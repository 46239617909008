import { Action } from "../actions";
import { FirebaseReducerModel } from "../../models";

export default (state:FirebaseReducerModel = {activeGeneration: "GEN_TWO", nexomon: null, nexomonGen1: null, activeNexomon: null, skills: null, items: null, images: null}, action:{type:any, payload:any}) => {
	switch(action.type) {
		case Action.firebase.getAllNexomon.fulfilled: 
			return {...state, nexomon: action.payload}
		case Action.firebase.getAllNexomonGen1.fulfilled:
			return {...state, nexomonGen1: action.payload}
		case Action.firebase.setActiveGeneration.type:
			return {...state, activeGeneration: action.payload}
		case Action.firebase.setActiveNexomon.type:
			return {...state, activeNexomon: action.payload}
		case Action.firebase.getAllSkills.fulfilled:
			return {...state, skills: action.payload}
		case Action.firebase.getAllItems.fulfilled:
			return {...state, items: action.payload}
		case Action.firebase.getAllImages.fulfilled:
			return {...state, images: action.payload}
		default:
			return state;
	};
}