import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { setCookieAcceptance } from '../../state/actions/data.actions';
import { State } from '../../models';
import './cookie-acceptance.styles.scss';

export interface CookieAcceptanceProps {
	mediaType:string;
}

const CookieAcceptance = (props:CookieAcceptanceProps) => {
	const { mediaType } = props;

	const dispatch = useDispatch();
	const cookieAccepted = useSelector((state:State) => {
		return state?.data?.cookieAccepted;
	});
	const [checked, setChecked] = useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.currentTarget.checked);
	}

	const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
		dispatch(setCookieAcceptance());
	}
	
	if(!cookieAccepted){
	return (
		<div className={clsx('cookie-policy-container', mediaType)}>
			<div className={'cookie-policy'}>
				<div className={'cookie-message'}>
					<span>
						This website "Nexopad.com" uses "cookies" to help us make this a better website for all.  To find out more about the cookies used, see our <a href={'/privacy-policy'}>Privacy Policy</a>.
					</span>
				</div>
				<div className={'accept-policy'}>
					<div className={'accept-policy-message'}>
						<span>I accept the cookies from this site.</span>
					</div>
					<div className={'accept-policy-input'}>
						<input type="checkbox" checked={checked} onChange={handleChange} />
					</div>
				</div>
				
			</div>
			<div className={'actions'}>
				<div className='confirm-policy'>
					<button onClick={handleSubmit} disabled={!checked}>Continue</button>
				</div>
			</div>
		</div>
	)
	}
	else {
		return null;
	}
}

export default CookieAcceptance;