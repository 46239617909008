import database from './database.png';
import items from './items.png';
import team from './team.png';
import profile from './profile.png';
import story from './story.png';
import locations from './locations.png';
import home from './home.png';
import social from './social.png';

type Icon = 'database' | 'items' | 'team' | 'profile' | 'story' | 'social' | undefined;

const icons = (iconId:string):string => {
	switch(iconId) {
		case 'database': return database;
		case 'items': return items;
		case 'team': return team;
		case 'profile': return profile;
		case 'story': return story;
		case 'social': return social;
		case 'locations': return locations;
		case 'home': return home;
		default: 
			return '';
	}
}

export default icons;