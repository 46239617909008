import React from 'react';
import createjs from 'createjs-module';
import './animated-background.styles.scss';
import GenerateImageBackground from './generate-background/generate-image-background.js';
import clsx from 'clsx';

class ImageBackgroundComponent extends React.Component {
	
	constructor(props){
		super(props);
		this.state = {
			stage: null,
			name: props.name
		}
	}

	componentDidUpdate(prevProps){
		
		if(this.state.stage === null){
			const  stage = new createjs.Stage(this.props.id);
			stage.update();
			stage.mouseEnabled = false;
			this.setState({stage: stage})
		}
		if(this.props.name !== prevProps.name){
			if(this.state.stage !== null){
				let stage = this.state.stage;
				stage.enableDOMEvents(false);
				stage.removeAllEventListeners();
				stage.removeAllChildren();
				stage.canvas = null;
				stage = null;
				this.setState({stage: null})
			}
			
		}
	}

	componentDidMount(){
		const  stage = new createjs.Stage(this.props.id);
		stage.update();
		stage.mouseEnabled = false;
		this.setState({stage: stage});
	}

	componentWillUnmount () {
		if(this.state.stage !== null){
			let stage = this.state.stage;
			stage.enableDOMEvents(false);
			stage.removeAllEventListeners();
			stage.removeAllChildren();
			stage.canvas = null;
			stage = null;
			this.setState({stage: null});
			}
	}
	render() {
		if(this.state.name && this.props.size){
			//console.log(this.state.name);
		return (
			<canvas height={this.props.height} width={this.props.width} id={this.props.id} className={clsx('image-background', this.props.mediaType, this.props.classes)} >
				{this.state.stage ? <GenerateImageBackground height={this.props.height} width={this.props.width} stage={this.state.stage} name={this.props.name} noShadow={this.props.noShadow} size={this.props.size} activeGeneration={this.props.activeGeneration} imageRef={this.props.imageRef}/> : null}
			</canvas>
		)}
		else {
			return null;
		}
	}
	
}

export default ImageBackgroundComponent;