import React, { useState, useEffect } from 'react';
import './landing-page.styles.scss';
import { MediaProp } from '../../common/components/media-mapper/media-mapper.comonents';
import Firebase, { IFirebase } from '../../common/api/firebase/firebase';
import { Nexomon } from '../../common/models';
import clsx from 'clsx';


export interface StateProps {
	nexomon: Nexomon[],
	evolutionsUpdate: (firebase:Firebase, nexomon:Nexomon[], list: number[]) => void
}

export interface DispatchProps {
}

export interface OwnProps {
	mediaType: MediaProp
}

export type LandingPageProps = StateProps & DispatchProps & OwnProps;

export const LandingPageComponent = (props:LandingPageProps & {mediaType: MediaProp} & IFirebase) => {
	const {mediaType } = props;
	
	return (
        <div className={clsx('landing-page-container', mediaType)}>

			<div className={clsx('landing-page', mediaType)}>
				Checkout the work in progress walkthrough <a href={'https://nexopad.com/guide/walkthrough'}>here</a>
			</div>
        </div>
    )
}

export default LandingPageComponent;