import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import promiseMiddleware from 'redux-promise-middleware';
import appReducer from './reducers';
import logger from 'redux-logger';
import { State, FirebaseReducerModel } from '../models';


const cookieAccepted = window.localStorage.getItem('cookieAccepted') && window.localStorage.getItem('cookieAccepted') !== '' ? JSON.parse(window.localStorage.getItem('cookieAccepted') as string) : false;
const versionMatch = !!(process.env.REACT_APP_APP_VERSION && (process.env.REACT_APP_APP_VERSION === window.localStorage.getItem('version')))
const activeGeneration = window.localStorage.getItem('activeGen') && window.localStorage.getItem('activeGen') !== '' ? JSON.parse(window.localStorage.getItem('activeGen') as string) : 'GEN_TWO';
const nexomon_1 = window.localStorage.getItem('nexomonGen1') && window.localStorage.getItem('nexomonGen1') !== '' ? JSON.parse(window.localStorage.getItem('nexomonGen1') as string) : [];
const nexomon_2 = window.localStorage.getItem('nexomon') && window.localStorage.getItem('nexomon') !== '' ? JSON.parse(window.localStorage.getItem('nexomon') as string) : [];
const skills = window.localStorage.getItem('skills') && window.localStorage.getItem('skills') !== '' ? JSON.parse(window.localStorage.getItem('skills') as string) : null;
const items = window.localStorage.getItem('items') && window.localStorage.getItem('items') !== '' ? JSON.parse(window.localStorage.getItem('items') as string) : null;
const images = window.localStorage.getItem('images') && window.localStorage.getItem('images') !== '' ? JSON.parse(window.localStorage.getItem('images') as string) : null;

const firebase:FirebaseReducerModel = {images: images, activeGeneration: activeGeneration, nexomon: nexomon_2, nexomonGen1: nexomon_1, activeNexomon: null, skills: skills, items: items};
const data = {cookieAccepted: cookieAccepted, user: null}
const localState:State = versionMatch ? {firebase: firebase, data: data} : {data: data};
const middlewares = [promiseMiddleware];
if(process.env.NODE_ENV !== 'production'){
	middlewares.push(logger);
}
const store = createStore (
	appReducer,
	localState,
	composeWithDevTools(applyMiddleware(...middlewares))
);

store.subscribe(() => {
	if(process.env.REACT_APP_APP_VERSION !== null && (process.env.REACT_APP_APP_VERSION !== window.localStorage.getItem('version'))){
		window.localStorage.setItem('version', process.env.REACT_APP_APP_VERSION as string)
	}
	if(store.getState().firebase.activeGeneration){
		window.localStorage.setItem('activeGen', JSON.stringify(store.getState().firebase.activeGeneration));
	}

	//nexomon
	if(store.getState().firebase.nexomon){
		window.localStorage.setItem('nexomon', JSON.stringify(store.getState().firebase.nexomon));
	}
	if(store.getState().firebase.nexomonGen1){
		window.localStorage.setItem('nexomonGen1', JSON.stringify(store.getState().firebase.nexomonGen1));
	}
	//skills
	if(store.getState().firebase.skills){
		window.localStorage.setItem('skills', JSON.stringify(store.getState().firebase.skills));
	}

	//items 
	if(store.getState().firebase.items && store.getState().firebase.items?.length){
		window.localStorage.setItem('items', JSON.stringify(store.getState().firebase.items));
	}

	//images 
	if(store.getState().firebase.images && Object.keys(store.getState().firebase.images).length){
		window.localStorage.setItem('images', JSON.stringify(store.getState().firebase.images));
	}

	//Cookie Accepted
	if(store.getState().data.cookieAccepted){
		window.localStorage.setItem('cookieAccepted', 'true');
	}
})

export default store;