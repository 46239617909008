import React, { useEffect, useState } from 'react';
import { MediaProp } from '../../common/components/media-mapper/media-mapper.comonents';
import { Nexomon, ImageRef, Element } from '../../common/models';
import Firebase from '../../common/api/firebase';
import ListCard from '../../common/components/nexomon/list-card/list-card.component';
import { IFirebase } from '../../common/api/firebase/firebase';
import './nexopedia.styles.scss';
import clsx from 'clsx';
import { AdSenseDesktopInList, AdSenseMobileInLine, AdSenseMobileAboveTheFold, AdSenseMobileBelowTheFold} from '../../common/components/adsense-components/adsense-components';
import { useHistory } from 'react-router-dom';
import nexo1 from '../../assets/nexo1.png';
import nexo2 from '../../assets/nexo2.png';
import search from '../../assets/icons/search.png';

import { setActiveGEN } from '../../common/state/actions/firebase.actions';
import ElementDisplayComponent from '../../common/components/nexomon/card/element-display/element-display.component';

export interface StateProps {
	nexomon: Nexomon[];
	nexomonGen1: Nexomon[];
	activeGeneration: "GEN_TWO" | "GEN_ONE";
	activeNexomon: number | null;
	images: {[key:string]: ImageRef} | null;
}

export interface DispatchProps {
	getNexomon: (firebase:Firebase) => void;
	getNexomonGen1: (firebase:Firebase) => void;
	updateGeneration: (generation: "GEN_TWO" | "GEN_ONE") => void;
}

export interface OwnProps {
	mediaType: MediaProp;
	
}

export interface NexopediaComponentProps extends StateProps, DispatchProps, OwnProps {
	getNexomonById: (id:number) => Nexomon | null;
}

const sortNexomon = (a:Nexomon, b:Nexomon):number => {
	return a.number > b.number ? 1 : (a.number < b.number) ? -1 : 0;
}
const NexopediaComponent = (props: NexopediaComponentProps  & IFirebase) => {
		const { images, activeGeneration, activeNexomon, nexomon, nexomonGen1, mediaType, updateGeneration } = props;
		
		const history = useHistory();
		const [searchValue, setSearchValue] = useState('');
		const [results, setResults] = useState<Nexomon[] | null>(null)
		const [typeSearch, setTypeSearch] = useState<Element | null>(null);
		const [raritySearch, setRaritySearch] = useState<number | null>(null);
		const [advanced, setAdvanced] = useState(false);
		useEffect(() => {
			if(activeNexomon) {
				const element = document.querySelector(`.list-card-container.nexomon-${activeNexomon}`);
				const rect = element?.getBoundingClientRect() // get rects(width, height, top, etc)
				const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

				if(rect){
					(document.querySelector('.nexomon-cards') as HTMLDivElement).scroll({
						top: rect?.top + rect?.height / 2 - viewHeight / 2,
						behavior: 'smooth' // smooth scroll
					});
				}
			}
		}, [activeNexomon]);

		useEffect(() => {
			const list = activeGeneration === 'GEN_ONE' ? nexomonGen1 : nexomon;
			if(typeSearch){
				setResults(list.filter((n:Nexomon) => {
					return n.element === typeSearch;
				}));
			}
		}, [typeSearch]);

		useEffect(() => {
			const list = activeGeneration === 'GEN_ONE' ? nexomonGen1 : nexomon;
			if(raritySearch){
				setResults(list.filter((n:Nexomon) => {
					return n.rarity === raritySearch;
				}));
			}
		}, [raritySearch]);

		const setUpdatedResults = (inputValue?:string):void => {
			let result = null;
			if(!searchValue || searchValue === ''){
				setResults(null);
				return;
			}
			if(activeGeneration){
				switch(activeGeneration) {
					case 'GEN_ONE':
						if(inputValue){
							result = Object.assign([], nexomonGen1.filter((nexo:Nexomon) => { return nexo.name.toLowerCase().includes(searchValue.toLowerCase()) || String(nexo.number).includes(inputValue)}))
						}
						else {
							result = Object.assign([], nexomonGen1.filter((nexo:Nexomon) => { return nexo.name.toLowerCase().includes(searchValue.toLowerCase()) || String(nexo.number).includes(searchValue)}))
						}
						setResults(result);
						break;
					case 'GEN_TWO':
						if(inputValue){
							result = Object.assign([], nexomon.filter((nexo:Nexomon) => { return nexo.name.toLowerCase().includes(searchValue.toLowerCase()) || String(nexo.number).includes(inputValue)}))
						}
						else {
							result = Object.assign([], nexomon.filter((nexo:Nexomon) => { return nexo.name.toLowerCase().includes(searchValue.toLowerCase()) || String(nexo.number).includes(searchValue)}))
						}
						setResults(result);
						break;
					default:
						setResults(result);
						break;
				}
			}
			
		}

		const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
			if(searchValue !== event.currentTarget.value){
				setSearchValue(event.currentTarget.value);
				setUpdatedResults(event.currentTarget.value);
			}
		}

		return (
			<div className={clsx('nexopedia-page-container', `${mediaType}`)}>
				<div className={clsx('title-top', mediaType, { ['advanced']: advanced})}>
					<div className={clsx('search-container')}>
						
						<div className={'generation-selector'}>
							<div className={clsx('slider', {['switched']: activeGeneration === 'GEN_TWO'})}></div>
							<div className={'generation'} onClick={() => {updateGeneration('GEN_ONE')}}>
								<img src={nexo1} className={clsx({['selected']: activeGeneration === 'GEN_ONE'})}/>
							</div>
							<div className={'generation'} onClick={() => {updateGeneration('GEN_TWO')}}>
								<img src={nexo2}  className={clsx({['selected']:activeGeneration === 'GEN_TWO'})}/>
							</div>
						</div>
						<div className={'search-input'}>
							<input type={'text'} placeholder={'Name'} value={searchValue} onChange={handleChange} />
							<button ><img src={search} /></button>
						</div>
						<div className={'advanced-hint'}>
							<div>
								Use the Advanced Search to find Nexomon by type or rarity!
							</div>
						</div>
						<div className={clsx('reset', {['hide']: results === null})} onClick={() => {
							setResults(null);
							setSearchValue('');
							setTypeSearch(null);
							setRaritySearch(null);
							setAdvanced(false);
						}}>clear search</div>

						<div className="search-tab" onClick={() => {setAdvanced(!advanced)}} >advanced</div>
						<div className={'rarity-search'}>
							<div onClick={() => {setRaritySearch(1); setAdvanced(false);}} className={clsx('rarity-container', mediaType)}>
								<div className={clsx('rarity-box rarity-1')}>
									C
								</div>
								<div className={clsx('rarity-title', mediaType)}>
									common
								</div>
							</div>
							<div onClick={() => {setRaritySearch(2); setAdvanced(false);}} className={clsx('rarity-container', mediaType)}>
								<div className={clsx('rarity-box rarity-2')}>
									UC
								</div>
								<div className={clsx('rarity-title', mediaType)}>
									uncommon
								</div>
							</div>
							<div onClick={() => {setRaritySearch(3); setAdvanced(false);}} className={clsx('rarity-container', mediaType)}>
								<div className={clsx('rarity-box rarity-3')}>
									R
								</div>
								<div className={clsx('rarity-title', mediaType)}>
									rare
								</div>
							</div>
							<div onClick={() => {setRaritySearch(4); setAdvanced(false);}} className={clsx('rarity-container', mediaType)}>
								<div className={clsx('rarity-box rarity-4')}>
									MR
								</div>
								<div className={clsx('rarity-title', mediaType)}>
									mega rare
								</div>
							</div>
							<div  onClick={() => {setRaritySearch(5); setAdvanced(false);}} className={clsx('rarity-container', mediaType)}>
								<div className={clsx('rarity-box rarity-5')}>
									S
								</div>
								<div className={clsx('rarity-title', mediaType)}>
									starter
								</div>
							</div>
							<div onClick={() => {setRaritySearch(6); setAdvanced(false);}} className={clsx('rarity-container', mediaType)}>
								<div className={clsx('rarity-box rarity-6')}>
									L
								</div>
								<div className={clsx('rarity-title', mediaType)}>
									legendary
								</div>
							</div>
							{ activeGeneration === 'GEN_TWO' ? 
							<div onClick={() => {setRaritySearch(7); setAdvanced(false);}} className={clsx('rarity-container', mediaType)}>
								<div className={clsx('rarity-box rarity-7')}>
									U
								</div>
								<div className={clsx('rarity-title', mediaType)}>
									ultimate
								</div>
							</div> : null}
						</div>
						<div className={'link-group'}>
							<div className={clsx('element', {['selected']: typeSearch === 'normal', ['shaded']: typeSearch !== 'normal' })} onClick={() => { setTypeSearch('normal'); setAdvanced(false);}} >
								<ElementDisplayComponent greyscale={!!typeSearch && typeSearch !== 'normal'} showTitle={false} element={'normal'} mediaType={mediaType} size={40}/>
							</div>
							<div className={clsx('element', {['selected']: typeSearch === 'fire', ['shaded']: typeSearch !== 'fire' })} onClick={() => { setTypeSearch('fire'); setAdvanced(false);}} >
								<ElementDisplayComponent greyscale={!!typeSearch && typeSearch !== 'fire'} showTitle={false} element={'fire'} mediaType={mediaType} size={40}/>
							</div>
							<div className={clsx('element', {['selected']: typeSearch === 'water', ['shaded']: typeSearch !== 'water' })} onClick={() => { setTypeSearch('water'); setAdvanced(false);}} >
								<ElementDisplayComponent greyscale={!!typeSearch && typeSearch !== 'water'} showTitle={false} element={'water'} mediaType={mediaType} size={40}/>
							</div>
							<div className={clsx('element', {['selected']: typeSearch === 'plant', ['shaded']: typeSearch !== 'plant' })} onClick={() => { setTypeSearch('plant'); setAdvanced(false);}} >
								<ElementDisplayComponent greyscale={!!typeSearch && typeSearch !== 'plant'} showTitle={false} element={'plant'} mediaType={mediaType} size={40} />
							</div>
							<div className={clsx('element', {['selected']: typeSearch === 'mineral', ['shaded']: typeSearch !== 'mineral' })} onClick={() => { setTypeSearch('mineral'); setAdvanced(false);}} >
								<ElementDisplayComponent greyscale={!!typeSearch && typeSearch !== 'mineral'} showTitle={false} element={'mineral'} mediaType={mediaType} size={40}/>
							</div>
							<div className={clsx('element', {['selected']: typeSearch === 'wind', ['shaded']: typeSearch !== 'wind' })} onClick={() => { setTypeSearch('wind'); setAdvanced(false);}} >
								<ElementDisplayComponent greyscale={!!typeSearch && typeSearch !== 'wind'} showTitle={false} element={'wind'} mediaType={mediaType} size={40}/>
							</div>
							<div className={clsx('element', {['selected']: typeSearch === 'electric', ['shaded']: typeSearch !== 'electric' })} onClick={() => { setTypeSearch('electric'); setAdvanced(false);}} >
								<ElementDisplayComponent greyscale={!!typeSearch && typeSearch !== 'electric'} showTitle={false} element={'electric'} mediaType={mediaType} size={40}/>
							</div>
							{
								activeGeneration === 'GEN_TWO' ?
								<React.Fragment>
									<div className={clsx('element', {['selected']: typeSearch === 'psychic', ['shaded']: typeSearch !== 'psychic' })} onClick={() => { setTypeSearch('psychic'); setAdvanced(false);}} >
										<ElementDisplayComponent greyscale={!!typeSearch && typeSearch !== 'psychic'} showTitle={false} element={'psychic'} mediaType={mediaType} size={40}/>
									</div>
									<div className={clsx('element', {['selected']: typeSearch === 'ghost', ['shaded']: typeSearch !== 'ghost' })} onClick={() => { setTypeSearch('ghost'); setAdvanced(false);} }>
										<ElementDisplayComponent greyscale={!!typeSearch && typeSearch !== 'ghost'} showTitle={false} element={'ghost'} mediaType={mediaType} size={40}/>
									</div>
								</React.Fragment>
								: 
								null
							}
							{/* 
							<div className='hint'>
								click an element to jump to it's comparison chart
							</div> */}
						</div>
					</div>
					
				</div>
				{mediaType !== 'mobile' ? 
				<AdSenseDesktopInList  path={history.location.pathname}/>
				: <AdSenseMobileAboveTheFold path={history.location.pathname} />}
				<div className={clsx('nexomon-cards', mediaType)}>
					{ results ? 
						results.sort(sortNexomon).map((nexo:Nexomon, index:number) => {
							if((index + 1) % 24 === 1  && mediaType === 'mobile' && index !== 0){
								 
								return (
									<React.Fragment>
										{Math.ceil(Math.random() * 2) === 1 ? <AdSenseMobileAboveTheFold  path={history.location.pathname} /> : <AdSenseMobileBelowTheFold  path={history.location.pathname} /> }
										<a href={`/nexopedia/${nexo.name}`} key={`${nexo.name}-${nexo.number}`}> <ListCard mediaType={mediaType} activeGeneration={activeGeneration} key={nexo.number} nexomon={nexo} images={images}/></a>
									</React.Fragment>
								)}
							else if ((index + 1) % 24 === 1  && index !== 0){
								return (
								<React.Fragment>
									<AdSenseDesktopInList  path={history.location.pathname}/>
									<a href={`/nexopedia/${nexo.name}`} key={`${nexo.name}-${nexo.number}`}> <ListCard mediaType={mediaType} activeGeneration={activeGeneration} key={nexo.number} nexomon={nexo} images={images}/></a>
								</React.Fragment>)
							}
							else {
								return <a href={`/nexopedia/${nexo.name}`} key={`${nexo.name}-${nexo.number}`}> <ListCard mediaType={mediaType} activeGeneration={activeGeneration} key={nexo.number} nexomon={nexo} images={images}/></a>
							}
								
						})
						: activeGeneration === "GEN_ONE" ?
							(nexomonGen1 && nexomonGen1.length ?
								nexomonGen1.sort(sortNexomon).map((nexo:Nexomon, index:number) => {
									if((index + 1) % 24 === 1  && mediaType === 'mobile' && index !== 0){
								 
										return (
											<React.Fragment>
												{Math.ceil(Math.random() * 2) === 1 ? <AdSenseMobileAboveTheFold  path={history.location.pathname} /> : <AdSenseMobileBelowTheFold  path={history.location.pathname} /> }
												<a href={`/nexopedia/${nexo.name}`} key={`${nexo.name}-${nexo.number}`}> <ListCard mediaType={mediaType} activeGeneration={activeGeneration} key={nexo.number} nexomon={nexo} images={images}/></a>
											</React.Fragment>
										)}
									else if ((index + 1) % 24 === 1  && index !== 0){
										return (
										<React.Fragment>
											<AdSenseDesktopInList  path={history.location.pathname}/>
											<a href={`/nexopedia/${nexo.name}`} key={`${nexo.name}-${nexo.number}`}> <ListCard mediaType={mediaType} activeGeneration={activeGeneration} key={nexo.number} nexomon={nexo} images={images}/></a>
										</React.Fragment>)
									}
									else {
										return <a href={`/nexopedia/${nexo.name}`} key={`${nexo.name}-${nexo.number}`}> <ListCard mediaType={mediaType} activeGeneration={activeGeneration} key={nexo.number} nexomon={nexo} images={images}/></a>
									}
								})
							: null)
							: 
							(nexomon && nexomon.length ?
								nexomon.sort(sortNexomon).map((nexo:Nexomon, index: number) => {
									if((index + 1) % 24 === 1  && mediaType === 'mobile' && index !== 0){
										return (
											<React.Fragment>
												{Math.ceil(Math.random() * 2) === 1 ? <AdSenseMobileAboveTheFold  path={history.location.pathname} /> : <AdSenseMobileBelowTheFold  path={history.location.pathname} /> }
												<a href={`/nexopedia/${nexo.name}`} key={`${nexo.name}-${nexo.number}`}> <ListCard mediaType={mediaType} activeGeneration={activeGeneration} key={nexo.number} nexomon={nexo} images={images}/></a>
											</React.Fragment>
										)
									}

									else if ((index + 1) % 24 === 1 && mediaType !== 'mobile' && index !== 0){
										return (
											<React.Fragment>
												<AdSenseDesktopInList  path={history.location.pathname}/>
												<a href={`/nexopedia/${nexo.name}`} key={`${nexo.name}-${nexo.number}`}> <ListCard mediaType={mediaType} activeGeneration={activeGeneration} key={nexo.number} nexomon={nexo} images={images}/></a>
											</React.Fragment>
										)
									}
									else {
										return <a href={`/nexopedia/${nexo.name}`} key={`${nexo.name}-${nexo.number}`}> <ListCard mediaType={mediaType} activeGeneration={activeGeneration} key={nexo.number} nexomon={nexo} images={images}/></a>
									}
									
								})
							: null)
						}
				</div>
			</div>
		)
}

export default NexopediaComponent;