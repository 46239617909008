import React from 'react';
import types from '../../../assets/types';
import { Element } from '../../models';
import './element-icon.styles.scss';
import clsx from 'clsx';

export interface ElementIconProps {
	element: Element;
	size?:number;
	noShadow?:boolean;
	greyscale?:boolean;
}

export const ElementIcon = (props: ElementIconProps) => {
	const {element,size, noShadow, greyscale} = props;
	return (
		<div className={clsx('element-icon-shadow', {['no-shadow']: noShadow, ['greyscale']: greyscale})}>
			<img src={types(element)} width={`${size ? size : 32}px`}/>
		</div>
	)
}