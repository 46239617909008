import Navbar, {StateProps, DispatchProps, NavbarProps} from './nav-bar.component';
import { State } from '../../common/models';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

export const mapStateToProps = (state:State):StateProps => {
	return {}
}

export const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => {
	return {}
}

export const mergeProps = (state:StateProps, dispatch:DispatchProps):NavbarProps => {
	return {}
}

export default connect(null, null)(Navbar);