import React, { useEffect, useState } from 'react';
import FoodImgRenderer from './FoodImgRenderer';


export interface FoodIconProps {
	food:string;
	size?:number;
	imageRef:string;
}

const FoodIconComponent = (props:FoodIconProps) => {
	const {food, size, imageRef} = props;
	const [renderState, setRenderState] = useState(false);

	useEffect(() => {
		setRenderState(false);
	}, [food])

	useEffect(() => {
		if(!renderState){
			setRenderState(true);
		}
	}, [renderState]);
	
	if(renderState){
		return (
			<div>
				<FoodImgRenderer size={ size ? size : 100} food={food} imageRef={imageRef} />
			</div>
	)}
	else {
		return null;
	}
}

export default FoodIconComponent;