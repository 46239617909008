import React from 'react';
import clsx from 'clsx';
import statusEffects from './status-effects';
import './status-effect.styles.scss';
import { Skill } from '../../../common/models';
import Skills from '../../../common/components/nexomon/card/skills/skills.component';
import SkillIconComponent from '../../../common/components/skill-icon/skill-icon.component';


export interface Status {
	id:string;
	description: string;
	skills: {id:number, chance:number, duration: {min:number, max:number}}[];
	
};
const StatusEffect = (props:{mediaType: string, gen2SkillList: Skill[]}) => {
	const { mediaType, gen2SkillList } = props;
	const status_order = ['sleep', 'paralyze', 'burn', 'freeze', 'poison', 'demotivated', 'bind', 'confusion', 'hex', 'sick', 'puzzled', 'leech','inception', 'cursed', 'provoked', 'nervous', 'frail', 'recover', 'survive', 'planted', 'invincible' ]

	const generateSkillItems = (status:string) => {
		const skillItems = statusEffects.find((stat:Status) => {
			return stat.id === status;
		})?.skills.map((skill:{id:number}) => { return skill.id});

		return skillItems?.map((skillId:number) => {
			const skill = gen2SkillList.find((skill:Skill) => { return skill.number === skillId})
			return (
				<div key={skillId} className={'skill-container'}>
					<div className={'skill-name'}>
						{skill?.name}
					</div>
					<div className={'skill-icon'}>
						<SkillIconComponent activeGeneration={'GEN_TWO'} skill={skill} imageRef={`https://us-central1-nexopad-2.cloudfunctions.net/api/images/skills/gen_2/${skillId}`}/>
					</div>
				</div>);
		});
	}
	return (
		<div className={clsx('status-effect-container', mediaType)}>
			<div className={clsx('guide-effects')}>
				<div className={clsx('status-effect-head')}>
					Status Effects are the result of some skills used in combat. There are benificial and detrimental effects. 
					Status Effects will last until they are cured either with by an Item or Skill. 
					Here you will see what each effect does. 
				</div>

				<div className={clsx('status-effects')}>
					{status_order.map((status:string) => {
						return (
							<div key={status} className={clsx('status-effect-item', mediaType)}>
								<div>
									<div className={clsx('status-effect-name', mediaType)}>
										{status[0].toUpperCase() + status.slice(1)}
									</div>
									<div className={clsx('status-effect-icon', mediaType)}>
										<img src={`https://us-central1-nexopad-2.cloudfunctions.net/api/images/status/${status}`} alt={`nexomon status effect ${status}`}/>
									</div>
								</div>
								<div className={clsx('status-effect-description', mediaType)}>
									{statusEffects ? statusEffects.find((s:Status) => { return s.id === status;})?.description : ''}
								</div>
								<div className={'status-effect-skills'}>
									<div className={'skill-group-title'}>
										Skills
									</div>
									<div className={'skill-group'}>
										{generateSkillItems(status)}
									</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default StatusEffect;