export type State  = {
	data?: DataReducerModel;
	firebase?: FirebaseReducerModel;
};

export interface DataReducerModel {
	loaded?: boolean;
	cookieAccepted: boolean;
	user: firebase.User | null;
}

export interface FirebaseReducerModel {
	nexomon: Nexomon[] | null;
	nexomonGen1: Nexomon[] | null;
	activeGeneration: "GEN_ONE" | "GEN_TWO";
	skills: {GEN_ONE: Skill[], GEN_TWO: Skill[]} | null;
	activeNexomon: number | null;
	items: Item[] | null;
	images: {[key:string]: ImageRef} | null;
}

export enum RARITY {
	'Common' = 1,
	'Uncommon' = 2,
	'Rare' = 3,
	'Very Rare' = 4,
	'Special' = 5,
	'Legendary' = 6,
	'Ultimate' = 7
}
export type Element = 'normal' | 'fire' | 'water' | 'plant' | 'mineral' | 'wind' | 'electric' | 'psychic' | 'ghost';

export interface Stats {
	hp: number,
	stamina: number,
	attack: number,
	defense: number,
	speed: number,
}
export interface Nexomon {
	
	name: string,
	number: number,
	detailItem?:string,
	element?: Element,
	rarity?: RARITY,
	evolvesAt?: number,
	evolvesTo?: number,
	startingStats?: Stats,
	foods?: string[],
	skillTree?: [{[key:number]: number}]
	evolution?: Evolution[];

}

export interface Item {
	name: string,
	id: string,
	description: string,
	category: string,
	plural: string,
	order?:number

}

export interface ImageRef {
	small?:string,
	tiny?:string,
	circular?:string,
	url?:string
}
/**
 * special:
 * 
 */

 export interface ImageLocation {
	 user:string;
	 description: string;
	 imageUrl: string;
	 date: number;
	 confirmed: number;
	 tier?: 1 | 2 | 3;

 }
export interface NexomonList {
	[key:string]: Nexomon;
}

export interface Evolution {
	name: string,
	number: number,
	level: number
}

export interface GEN_1 {
	"ATK": number;
	"DEF": number,
	"HP": number;
	"SPD": number;
	"STA": number;
	chain: string[];
	name: string;
	type: string;
	number: string;
	rarity:string;
	skillTree: {level:number, skill: number}[];
	location: string[];
	evolves: string;
}

export const GEN1_TYPE = (type:string) => {
	switch(type){
		case "01":  return "electric";
		case "02": return "fire";
		case "03": return "normal";
		case "04": return "mineral";
		case "05": return "plant";
		case "06": return "water";
		case "07": return "wind";
		default:
			return '';
	}
}

export interface Skill {
	"cost" : number,
  	"description" : string,
  	"element" : Element,
  	"name" : string,
  	"number" : number,
  	"accuracy": number,
  	"damage": number,
  	"effectToEnemy": string,
  	"effectToSelf": string
	"speed": number | null,
	"critical": number | null
}

export const EffectToEnemy = (effect:string):string => {
	switch(effect){
		case "bound":
		case "paralyzed":
		case "sleeping":
		case "frozen":
			return "Target skips 1 turn."
		case "confused":
			return "Target takes extra damage, and skips 1 turn, additional damage if nexomon is not swapped out."
		case "burnt":
		case "poisoned":
			return "Target recieves extra damage."
		case "speed down":
		case "element down":
		case "accuracy down":
			return "Reduces affected Stat for X turns."
		case "attack down":
			return "Target has reduced Attack Stat for duration of combat.";
		case "defense down":
			return "Target has reduced defense until next hit."
		default :
			return "";
	}
}

export const EffectToCaster = (effect:string):string => {
	switch(effect){
		case "health points healed":
		case "stamina healed":
			return "Caster recieves a restoration of the Stat.";
		case "blocking":
			return "Reduces damage to caster to one until hit.";
		case "attack up":
		case "defense up":
		case "element up":
		case "speed up":
		case "accuracy up":
			return "Caster recieves a boost to the Stat for X turns."
		default: 
			return "";
	}
}