import React from 'react';
import './skills.styles.scss';
import clsx from 'clsx';
import { Nexomon, State, Skill, Element, ImageRef } from '../../../../models';
import SkillIconComponent from '../../../skill-icon/skill-icon.component';
import { useSelector } from 'react-redux';
import { ElementIcon } from '../../../element-icon/element-icon';

export interface SkillsProps {
	activeGeneration: "GEN_ONE" | "GEN_TWO";
	nexomon:Nexomon;
	mediaType:string;
	images: {[key:string]: ImageRef}
}

const Skills = (props: SkillsProps) => {
	const {activeGeneration, images, mediaType, nexomon} = props;

	const skills:Skill[] = useSelector((state:State) => {
		return state.firebase?.skills ? state.firebase.skills[activeGeneration] : [];
	});

	const getSkillById = (level:string):Skill | undefined => {
		const id = nexomon?.skillTree ? nexomon.skillTree[parseInt(level)] : null;
		if(id){
			return skills.find((skill:Skill) => {
				return skill.number === Number(id)
			});
		}
	}

	return (
		<div className={clsx('skills-container', mediaType)}>
			<div className={clsx('skills-title', mediaType)}>
				skills
			</div>
			<div className={clsx('skills-body', mediaType)}>
				<div>
					<div className={clsx('skill-items', mediaType)}>
						{ nexomon.skillTree ? 
							Object.keys(nexomon.skillTree as {[key:number]:number}[]).map((k:string, index:number) => {
								return (
									<a style={{textDecoration: 'none'}} href={`https://guide.nexopad.com/${getSkillById(k)?.element}-skills#${getSkillById(k)?.name.split(' ').join('_').toLowerCase()}`}>
									<div key={index} className={clsx('skill-item', mediaType)} >
										
										<div className={clsx('skill-level', mediaType)}>
											<span>level</span> 
											<span>{k}</span>
										</div>
										
										<div className={clsx('skill-display', mediaType, {['gen-2']: activeGeneration === 'GEN_TWO'})}>
											<div className={clsx('skill-hero', mediaType)}>
												<div className={clsx('skill-icon', mediaType)}>
													{ images ? (mediaType === 'mobile' ?
														<SkillIconComponent skill={getSkillById(k)} activeGeneration={activeGeneration} size={80} imageRef={images[`skill_${activeGeneration === 'GEN_ONE' ? 'gen1' : 'gen2'}_${getSkillById(k)?.number}`].url || ''}/>
														:
														<SkillIconComponent skill={getSkillById(k)} activeGeneration={activeGeneration} size={200} imageRef={images[`skill_${activeGeneration === 'GEN_ONE' ? 'gen1' : 'gen2'}_${getSkillById(k)?.number}`].url || ''}/>
													) : null}
												</div>
											
											</div>
											<div className={clsx('right-container', mediaType, {['gen-2']: activeGeneration === 'GEN_TWO'})} >
												<div className={clsx('skill-header', mediaType,{['gen-2']: activeGeneration === 'GEN_TWO'})} >
													
													<div className={clsx('skill-name', mediaType)}>
														<span>{getSkillById(k)?.name}</span>
													</div>
													<div className={clsx('skill-element', mediaType)}>
														{ mediaType === 'mobile' ? 
															<ElementIcon element={getSkillById(k)?.element as Element} size={30} noShadow={true}/>
															:
															<ElementIcon element={getSkillById(k)?.element as Element} size={75} noShadow={true}/>
														}
													</div>
													
												</div>
												<div>
													<div className={clsx('skill-description', mediaType)}>
														{
															getSkillById(k)?.description
														}
													</div>
												</div>
												<div className={clsx('skill-footer', mediaType)}>
													<div className={clsx('skill-details', mediaType)}>
														<table>
															<thead>
																<tr>
																	<th>POW</th>
																	<th>ACC</th>
																	<th>COST</th>
																	{ activeGeneration === 'GEN_TWO' ? 
																		<React.Fragment>
																			<th>SPD</th>
																			<th>CRIT %</th>
																		</React.Fragment>
																		:
																		null
																	}
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<span>{getSkillById(k)?.damage}</span>
																	</td>
																	<td>
																		<span>{getSkillById(k)?.accuracy === -1 ? '100' : getSkillById(k)?.accuracy}</span>
																	</td>
																	<td>
																		<span>{getSkillById(k)?.cost}</span>
																	</td>
																	{ activeGeneration === 'GEN_TWO' ? 
																		<React.Fragment>
																			<td>
																				<span>{getSkillById(k)?.speed}</span>
																			</td>
																			<td>
																				<span>{getSkillById(k)?.critical}</span>
																			</td>
																		</React.Fragment>
																		:
																		null
																	}
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
									</a>
								)})
							: 
							null
						}
					</div>
				</div>
			</div>
		</div>
	)
};

export default Skills;