import { Element } from "../../../models"

export interface ElementCompare {
	element: Element;
	strongAgainst: string[];
	weakAgainst: string[];
	order: number;
}

export const elementComparisons:ElementCompare[] = [
	{
		element: 'normal' as Element,
		strongAgainst: [],
		weakAgainst: ['ghost','psychic'],
		order:0
	},
	{
		element: 'fire'  as Element,
		strongAgainst: ['plant', 'wind', 'ghost'],
		weakAgainst: ['fire', 'water', 'mineral'],
		order:1
	},
	{
		element: 'water'  as Element,
		strongAgainst: ['fire', 'mineral', 'ghost'],
		weakAgainst: ['water', 'plant', 'electric'],
		order:2
	},
	{
		element: 'plant'  as Element,
		strongAgainst: ['water', 'electric', 'psychic'],
		weakAgainst: ['fire', 'plant', 'wind'],
		order:3
	},
	{
		
		element: 'wind'  as Element,
		strongAgainst: ['plant', 'mineral'],
		weakAgainst: ['fire', 'electric', 'ghost'],
		order:4
	},
	{
		element: 'electric'  as Element,
		strongAgainst: ['water', 'wind', 'psychic'],
		weakAgainst: ['plant', 'electric', 'mineral'],
		order:5
	},
	{
		element: 'mineral'  as Element,
		strongAgainst: ['fire', 'electric'],
		weakAgainst: ['water', 'wind', 'psychic'],
		order:6
	},
	{
		element: 'ghost'  as Element,
		strongAgainst: ['normal', 'wind', 'psychic'],
		weakAgainst: ['fire', 'water'],
		order:7
	},
	{
		element: 'psychic'  as Element,
		strongAgainst: ['mineral', 'psychic'],
		weakAgainst: ['normal', 'electric', 'ghost'],
		order:8
	},
]

export const elements = [
	'normal',
	'fire',
	'water',
	'plant',
	'wind',
	'electric',
	'mineral',
	'ghost',
	'psychic',
]