import electric from './electric-circular.png';
import fire from './fire-circular.png';
import ghost from './ghost-circular.png';
import mineral from './mineral-circular.png';
import normal from './normal-circular.png';
import plant from './plant-circular.png';
import psychic from './psychic-circular.png';
import water from './water-circular.png';
import wind from './wind-circular.png';

import { Element } from '../../common/models/index';


const elementType = (type?:Element):string => {
	switch(type){
		case 'electric': return electric;
		case 'fire': return fire;
		case 'ghost': return ghost;
		case 'mineral': return mineral;
		case 'normal': return normal;
		case 'plant': return plant;
		case 'psychic': return psychic;
		case 'water': return water;
		case 'wind': return wind;
		default: return '';
	}
}

export default elementType;