import React from 'react';

import './footer.styles.scss';
import clsx from 'clsx';
import MediaMapper from '../../common/components/media-mapper/media-mapper.comonents';

export interface FooterProps {
	mediaType:string;
}

const FooterComponent = (props:FooterProps) => {
	const { mediaType } = props;
	return (
		<div className={clsx('footer-container', mediaType)}>
			<div className={clsx('footer-content', mediaType)} >
				<div className={clsx('footer-row', mediaType)}>
					<a href={'/terms-and-conditions'}>Terms and Conditions</a>
					<a className={'right'} href={'/privacy-policy'}>Privacy Policy</a>
					<a href={'/disclaimer'}>Disclaimer</a>
					<a className={'right'} href={'mailTo:support@nexopad.com?subject=Nexopad%20Support%20Customer%20Comment'}>Contact Us</a>
				</div>
				<div className={clsx('bottom footer-row', mediaType)}>
					<a href={'admin@vewointeractive.com'}>&#169; {new Date().getFullYear()} All images and data used with permission.  </a>
				</div>
			</div>
		</div>
	)
}

const FooterMediaMap = (props:FooterProps) => {
	return (
		<MediaMapper>
			<FooterComponent {...props} mediaType={'mobile'} />
			<FooterComponent {...props} mediaType={'tablet'} />
			<FooterComponent {...props} mediaType={'desktop'} />
		</MediaMapper>
	)
}

export default FooterMediaMap;


/**
 * things to include:
 * 
 * copyrights
 * 
 * terms of use
 * privacy policy
 * 
 * site links
 * 
 * follow links
 * 
 * contact information
 * 
 * email / text subscription
 */