import Firebase from "../firebase";
import { Nexomon, Evolution } from "../../models";

export const evolutionUpdate = (firebase:Firebase, nexomon:Nexomon[], list: number[]) => {
	
	let updaters = nexomon.filter((nexo:Nexomon) => {
		return list.includes(nexo.number);
	});

	if(updaters && updaters.length){
		let evolutions: Evolution[] = updaters?.map((nexo:Nexomon) => {
			return {
				name: nexo.name.toLowerCase(),
				number: nexo.number,
				level: nexo.evolvesAt || 0
			}
		});

		updaters?.forEach((updater:Nexomon) => {
			firebase.db.collection('nexomon').doc(updater.name.toLowerCase()).update({evolution: evolutions});
		})
	}
	
}