import { Action } from "../actions";

export default (state:{loaded?:boolean, cookieAccepted:boolean, user: firebase.User | null} = {cookieAccepted:false, user: null}, action:{type:any, payload:any}) => {
	switch(action.type) {
		case Action.data.dataLoaded.type : 
			return {...state, loaded: false}

		case Action.data.setCookieAccepted.type: {
			return {...state, cookieAccepted: true }
		}
		case Action.data.updateUser.type: {
			return {...state, user: action.payload}
		}
		default:
			return state;
	};
}