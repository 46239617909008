import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import NexomonCardComponent from '../../common/components/nexomon/card/nexomon-card.component';
import { MediaProp } from '../../common/components/media-mapper/media-mapper.comonents';
import './nexopedia-item.styles.scss';
import { useSelector } from 'react-redux';
import { State, Nexomon } from '../../common/models';
import clsx from 'clsx';
import right from '../../assets/right.png';
import left from '../../assets/left.png';
import { AdSenseDesktopInList, AdSenseMobileInLine, AdSenseMobileAboveTheFold } from '../../common/components/adsense-components/adsense-components';
export interface NexopediaItemProps {
	mediaType: MediaProp;
}

const sortNexomon = (a:Nexomon, b:Nexomon):number => {
	return a.number > b.number ? 1 : (a.number < b.number) ? -1 : 0;
}

const NexopediaItem = (props:NexopediaItemProps) =>  {
	const { mediaType } = props;
	const match = useRouteMatch<{id:string}>();

	const [nexoId, setNexoId] = useState('');

	useEffect(() => {
		if(nexoId !== match.params.id){
			setNexoId(match.params.id)
		}
	}, [match.params.id]);

	const activeGeneration = useSelector((state:State) => {
		return state.firebase?.activeGeneration;
	});

	const images = useSelector((state:State) => {
		return state.firebase?.images ? state.firebase?.images : null;
	})
	const nexomonList = useSelector((state:State) => {
		if(activeGeneration === "GEN_TWO"){
			return state.firebase?.nexomon;
		}
		else {
			return state.firebase?.nexomonGen1;
		}
	});
	const nexomonId = match.params.id;

	const nexomon = useSelector((state:State) => {
		if(isNaN(Number(nexomonId))){
			const nexo = nexomonList?.find((n:Nexomon) => {
				return n.name.toLowerCase() === nexomonId.toLowerCase() as string;
			});

			return nexo ? nexo : null;
		}
		else {
			const nexo = nexomonList?.find((n:Nexomon) => {
				return n.number.toString() === nexomonId as string;
			});

			return nexo ? nexo : null;
		}
	});

	const getPrevByMatchId = ():Nexomon | null => {
		const list:Nexomon[] | undefined = nexomonList?.sort(sortNexomon);
		const index = list?.indexOf(nexomon as Nexomon);
		const listLength = (list?.length as number) - 1;
		if(index === 0){
			return list ? list[listLength] : null;
		}
		else {
			return list ? list[(index as number) - 1] : null;
		}
	}

	const getNextByMatchId = ():Nexomon | null => {
		const list:Nexomon[] | undefined = nexomonList?.sort(sortNexomon);
		const index = list?.indexOf(nexomon as Nexomon);
		const listLength = (list?.length as number) - 1;
		if(index && (index === listLength)) {
			return list ? list[0] : null;
		}
		else {
			return list ? list[(index as number) + 1] : null;
		}
	}

	const formatNumber = (num:number) => {
		if(num < 10) {
			return `00${num}`;
		}
		else if (num < 100) {
			return `0${num}`;
		}
		else {
			return num;
		}
	}

	const history = useHistory();
	return (
		<div  className={clsx('nexopedia-item-container', mediaType)}>
			<div className="item-navigation" >
				<div className="previous">
					<div onClick={() => { history.push(`/nexopedia/${getPrevByMatchId()?.name as string}`)}}>
						<div className={'title'} >
							<div>{ getPrevByMatchId()?.name.split('_')[0]}</div> 
							<div>#{formatNumber(getPrevByMatchId()?.number as number)}</div>
						</div>
						<div className="previous-icon">
							<img src={right} alt={'previous'} />
						</div>
					</div>
				</div>
				<div className="next">
					<div onClick={() => { history.push(`/nexopedia/${getNextByMatchId()?.name as string}`)}}>
						<div className={'title'} >
							<div>{ getNextByMatchId()?.name.split('_')[0]}</div> 
							<div>#{formatNumber(getNextByMatchId()?.number as number)}</div>
						</div>
						<div className={"next-icon"}>
							<img src={left} alt={'next'} />
						</div>
					</div>
				</div>
			</div>
			{ nexoId === match.params.id ? (mediaType !== 'mobile' ? 
				<AdSenseDesktopInList path={history.location.pathname}/>
				:
				<AdSenseMobileAboveTheFold path={history.location.pathname} />) : <div style={{width: '100vw', height: '200px'}} ></div>}
			<div  className={clsx('nexopedia-item', mediaType)}>
				<NexomonCardComponent images={images} activeGeneration={activeGeneration as string} nexomonId={match.params.id} mediaType={mediaType} handleClose={() => { history.push('/nexopedia')}}/>
			</div>
			<div className="item-navigation" >
				<div className="previous">
					<div onClick={() => { history.push(`/nexopedia/${getPrevByMatchId()?.name as string}`)}}>
						<div className={'title'} >
							<div>{ getPrevByMatchId()?.name.split('_')[0]}</div> 
							<div>#{formatNumber(getPrevByMatchId()?.number as number)}</div>
						</div>
						<div className="previous-icon">
							<img src={right} alt={'previous'}/>
						</div>
					</div>
				</div>
				<div className="next">
					<div onClick={() => { history.push(`/nexopedia/${getNextByMatchId()?.name as string}`)}}>
						<div className={'title'} >
							<div>{ getNextByMatchId()?.name.split('_')[0]}</div> 
							<div>#{formatNumber(getNextByMatchId()?.number as number)}</div>
						</div>
						<div className={"next-icon"}>
							<img src={left} alt={'next'} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NexopediaItem;