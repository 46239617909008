import React, { useState, useEffect } from 'react';
import { MediaProp } from '../../media-mapper/media-mapper.comonents';
import { Nexomon, State, Element, Evolution, ImageRef } from '../../../models';
import ElementBackground from '../../animated/animated-background/element-background.component.js';
import ImageBackground from '../../animated/animated-background/image-background.component.js';
import './nexomon-card.styles.scss';
import clsx from 'clsx';
import StartingStats from './stats/starting-stats/starting-stats.component';
import { useSelector, useDispatch } from 'react-redux';
import ElementDisplay from './element-display/element-display.component';
import Food from './food/food.component';
import Skills from './skills/skills.component';
import EvolutionDisplay from './evolution/evolution.component';
import GenOneStatCalculator from './stats/gen-one-stat-calculator/gen-one-stat-calculator.component';
import Firebase, { withFirebase } from '../../../api/firebase';
import { getAllNexomon, getAllGen1Nexomon, setActiveGEN } from '../../../state/actions/firebase.actions';
import { useHistory } from 'react-router-dom';
import { AdSenseMobileBelowTheFold, AdSenseDesktopInList } from '../../adsense-components/adsense-components';
//import LocationComponent from './location/location.component';

export interface NexomonCardProps {
	nexomonId: string;
	mediaType: MediaProp;
	images: {[key:string]:ImageRef} | null;
	handleClose?: (event?:React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const getRarityById = (id:number) => {
	switch(id) {
		case 1: return 'COMMON';
		case 2: return 'UNCOMMON';
		case 3: return 'RARE';
		case 4: return 'MEGA RARE';
		case 5: return 'STARTER';
		case 6: return 'LEGENDARY';
		case 7: return 'ULTIMATE';
		default: return '';
	}
}

const formatNumber = (num:number) => {
	if(num < 10) {
		return `00${num}`;
	}
	else if (num < 100) {
		return `0${num}`;
	}
	else {
		return num;
	}
}

const NexomonCardComponent = (props:NexomonCardProps & {firebase:Firebase}) => {
	const {mediaType, nexomonId, images, handleClose, firebase} = props;
	
	const [nexomon, setNexomon] = useState<Nexomon | null>();
	const [settingGen, setSettingGen] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	
	const activeGeneration = useSelector((state:State) => {
		return state.firebase?.activeGeneration ? state.firebase.activeGeneration : "GEN_TWO";
	});

	const nexomonList: Nexomon[] | null = useSelector((state:State) => {
		return state.firebase?.nexomon ? state.firebase.nexomon : [];
	})

	const nexomonGen1List = useSelector((state:State) => {
		return state.firebase?.nexomonGen1 ? state.firebase?.nexomonGen1 : [];
	})

	useEffect(() => {
		if((!nexomonList || nexomonList.length === 0)) {
			if(firebase) {
				dispatch(getAllNexomon(firebase));
			}
		}
		if((!nexomonGen1List || nexomonGen1List.length === 0)) 
		{
			if(firebase) {
				dispatch(getAllGen1Nexomon(firebase));
			}
		}
	}, [firebase, dispatch, nexomonList, nexomonGen1List]);

	const nexomonSelector = useSelector((state:State) => {
			const fullList = nexomonGen1List.concat(nexomonList).filter((n:Nexomon) => { return n.name.toLowerCase() !== 'unknown'});
			const nexo = fullList.find((n:Nexomon) => {
				return n.name.toLowerCase() === nexomonId.toLowerCase() as string;
			});
			if (nexo && nexomonList.find((n:Nexomon) => {return n.name.toLowerCase() === nexo.name.toLowerCase()}) && !settingGen){
				if(activeGeneration !== "GEN_TWO"){
					setSettingGen(true);
					dispatch(setActiveGEN("GEN_TWO"));
				}
			}
			else if(nexo && nexomonGen1List.find((n:Nexomon) => {return n.name.toLowerCase() === nexo.name.toLowerCase()}) && !settingGen){
				if(activeGeneration !== "GEN_ONE"){
					setSettingGen(true);
					dispatch(setActiveGEN("GEN_ONE"));
				}
			}
			
			if(!nexo){
				history.push('/nexopedia');
			}
			else {
				return nexo;
			}
		
	});

	useEffect(() => {
		setNexomon(nexomonSelector);
	}, [nexomonSelector]);

	const viewWidth =  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	if(nexomon) {
	return (
		<div className={clsx('nexomon-card-container', `${mediaType}`, {['gen2']: activeGeneration === 'GEN_TWO'})}>
				<div className={clsx('nexomon-card', `${mediaType}`)}> 
					<div className={clsx('close-card', `${mediaType}`)} onClick={handleClose}>x</div>
					<div className={clsx('top-content', `${mediaType}`)}>
						<div className={clsx('top-content-left', `${mediaType}`)}>
							<div className={clsx('hero-image', `${mediaType}`)}>
								{mediaType === 'mobile' ?
									<React.Fragment>
										<ElementBackground id={`nexomon_${activeGeneration}_${nexomon.number}`} rarity={ nexomon.rarity } height={viewWidth - 50} width={viewWidth -50} name={nexomon.name.toLowerCase()} mediaType={mediaType}/>
										{images ? 
											<ImageBackground activeGeneration={activeGeneration} id={`nexomon-image_${activeGeneration}_${nexomon.number}`} name={nexomon.name.toLowerCase()} height={viewWidth - 50} width={viewWidth - 50} size={'small'} mediaType={mediaType} imageRef={images[nexomon.name.toLowerCase()]['small']}/>
											: null
										}
									</React.Fragment>
								:
									<React.Fragment>
										<ElementBackground id={`nexomon_${activeGeneration}_${nexomon.number}`} rarity={ nexomon.rarity } height={350} width={350} name={nexomon.name.toLowerCase()} />
										{ images ? 
											<ImageBackground activeGeneration={activeGeneration} id={`nexomon-image_${activeGeneration}_${nexomon.number}`} name={nexomon.name.toLowerCase()} height={350} width={350} size={'small'} imageRef={images[`${nexomon.name.toLowerCase()}`]['small']}/>
											: null
										}
									</React.Fragment>
								}
								<div className={clsx('rarity-type shadow', `${mediaType}`, `rarity-${nexomon.rarity}`)}>{getRarityById(nexomon.rarity as number)}</div>
								<div className={clsx('rarity-type', `${mediaType}`, `rarity-${nexomon.rarity}`)}>{getRarityById(nexomon.rarity as number)}</div>
								<div className={clsx('list-number shadow', `${mediaType}`, `rarity-${nexomon.rarity}`)}>#{formatNumber(nexomon.number as number)}</div>
								<div className={clsx('list-number', `${mediaType}`, `rarity-${nexomon.rarity}`)}>#{formatNumber(nexomon.number as number)}</div>
							</div>
						</div>
						<div className={clsx('top-content-right', `${mediaType}`)}>
							<div className={clsx('card-title', `${mediaType}`, {['shifted']: nexomon.name.split('_')[0].length >= 12})}>
								{nexomon.name.split('_')[0]}
							</div>
							<div className={clsx('content-row', `${mediaType}`)}>
								<ElementDisplay showLink={true} mediaType={mediaType} element={nexomon?.element as Element}/>
								<StartingStats mediaType={mediaType} nexomon={nexomon} activeGeneration={activeGeneration} />
							</div>
							{ activeGeneration === "GEN_TWO" ?
								<div className={'detail-item'}>
									<div className={clsx('detail-title', mediaType)}>description</div>
									<div className={clsx('detail', mediaType, {['small']: (nexomon?.detailItem?.length && nexomon.detailItem.length > 150), ['tiny']: (nexomon?.detailItem?.length && nexomon.detailItem.length > 175 )})}>{ nexomon.detailItem}</div>
								</div>
								:
								<GenOneStatCalculator mediaType={mediaType} nexomon={nexomon} />
							}
						</div>
					</div>
					<div className={clsx('mid-content', `${mediaType}`)}>
						<div className={clsx('mid-content-left', `${mediaType}`)}>
							{ images ?
								<EvolutionDisplay mediaType={mediaType} activeGeneration={activeGeneration} evolutions={nexomon?.evolution as Evolution[]} nexoId={nexomon.number} name={nexomon.name} rarity={nexomon.rarity} images={images}/>
								: null
							}
						</div>
						{	activeGeneration === "GEN_TWO" ?
								<div className={clsx('mid-content-right', mediaType)}>
									{ images ? <Food images={images} foods={ nexomon.foods ? nexomon.foods : []} mediaType={mediaType}/> : null }
								</div>
							:
								<div>

								</div>
						}
					</div>
					<div className={clsx('bottom-content', mediaType)}>
						{images ? <Skills images={images} nexomon={nexomon} mediaType={mediaType} activeGeneration={activeGeneration}/> : null}
					</div>
					<div className={clsx('bottom-content')} style={{width: '100%'}}>
						{mediaType === 'mobile' ? <AdSenseMobileBelowTheFold path={history.location.pathname}/> : <AdSenseDesktopInList path={history.location.pathname} />}
						{/* <LocationComponent nexomon={nexomon} activeGeneration={activeGeneration} firebase={firebase}/> */}
					</div>
					
				</div>
		</div>
	)}
	else {
		return null;
	}
}

export default withFirebase(NexomonCardComponent);