import React from 'react';
import { ElementIcon } from '../../../element-icon/element-icon';
import { Element } from '../../../../models';
import './element-display.styles.scss';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
export interface ElementDisplayProps {
	element:Element;
	mediaType:string;
	showLink?:boolean;
	showTitle?:boolean;
	size?:number
	noShadow?:boolean;
	greyscale?:boolean;
}

const ElementDisplayComponent = (props: ElementDisplayProps) => {
	const { greyscale, showLink, size, noShadow, element, mediaType, showTitle } = props;
	const history = useHistory();
	return (
		<div className={clsx('element-display-container', mediaType)}>
			{ showTitle == false ? 
				 null : 
				<div className={'element-title'}>
					element
				</div>
			}
			<div className={'element-display'}>
				<div className={'element-display-icon'}>
					<ElementIcon greyscale={greyscale !== undefined ? greyscale : false} element={element} size={size ? size : 75} noShadow={noShadow}/>
				</div>
				<div className={'element-display-name'}>
					{element}
				</div>
			</div>
			<div className={'element-display-link'}>
				{ showLink ? 
				<div onClick={() => { window.location.assign(`${window.location.protocol}//${window.location.host}/guide/elements/#${element}`)}} className={'link'}>
					view chart
				</div> : null}
			</div>
		</div>
	)
}

export default ElementDisplayComponent;