import React, { useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Item, ImageRef } from '../../common/models';
import FoodIconComponent from '../../common/components/nexomon/card/food/food-icon-component';
import clsx from 'clsx';
import './category-view.styles.scss';
import { AdSenseDesktopInList, AdSenseMobileInLine, AdSenseMobileAboveTheFold, AdSenseMobileBelowTheFold } from '../../common/components/adsense-components/adsense-components';

export interface CategoryViewProps {
	getAllowed: (category:string, id?:string) => boolean | Item | undefined;
	items: Item[] | null;
	mediaType:string;
	images: {[key:string]: ImageRef} | null
}


const CategoryView = (props:CategoryViewProps) => {
	const { images, getAllowed, items, mediaType } = props;
	const {params} = useRouteMatch<{category:string}>();
	const history = useHistory();
	const [categoryList, setCategoryList] = useState<Item[] | null>(null);
	if(!getAllowed(params.category)){
		history.push('/items');
	}

	useEffect(() => {
		if(items && items.length > 0){
			setCategoryList((items as Item[]).filter((i:Item) => {
				return i.category === params.category;
			}));
		}
	},[items, params.category]);

	return (
		<div className={clsx('category-view-container', mediaType)}>
			<div className={clsx('category-header')}>
			{mediaType !== 'mobile' ? 
				<AdSenseDesktopInList path={history.location.pathname}/>
				: 
				<AdSenseMobileAboveTheFold path={history.location.pathname} />
			}
				<div className={clsx('category-title', {['shorten']: params.category === 'restoration'})}>{params.category !== 'restoration' && params.category !== 'special' ? `${params.category}s` : params.category}</div>
				<div className={clsx('category-help')}>
					Please select an item to view more details.
				</div>
			</div>
			
			<div className={clsx('category-view')}>
				{categoryList?.map((item:Item) => {
					return (
						<div key={item.id} className={'item-card'} onClick={() => { history.push(`/items/${params.category}/${item.id}`)}}>
							<div className={'item-card-icon'}>
								{images ? <FoodIconComponent food={item.id} size={100} imageRef={images[`item_${item.id}`].url || ''}/> : null }	
							</div>
							<div className={'item-card-name'}>
								{item.name}
							</div>
						</div>
					)
				})}
			</div>
			{mediaType !== 'mobile' ? 
				<AdSenseDesktopInList path={history.location.pathname}/>
				: 
				<AdSenseMobileBelowTheFold path={history.location.pathname}/>
			}
		</div>
	)
}

export default CategoryView;