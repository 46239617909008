import React, { Component, useEffect, useState } from 'react';
import { withFirebase } from '../../api/firebase';
import createjs from 'createjs-module';
import { imgSrcToBlob } from 'blob-util';
class SkillImgRenderer extends Component {

	constructor(props){
		super(props);
		this.state = {
			stage: null,
			name: props.name
		}
	}

	componentDidUpdate(prevProps){
		
		if(this.state.stage === null){
			const  stage = new createjs.Stage(this.props.id);
			stage.update();
			stage.mouseEnabled = false;
			this.setState({stage: stage})
		}
		if(this.props.id !== prevProps.id){
			if(this.state.stage !== null){
				
				this.state.stage.removeAllEventListeners();
				this.state.stage.removeAllChildren();
				this.state.stage.canvas = null;
				this.state.stage = null;
				this.setState({stage: null})
			}
			
		}
	}

	componentDidMount(){
		const  stage = new createjs.Stage(this.props.id);
		stage.update();
		stage.mouseEnabled = false;
		this.setState({stage: stage});
	}

	componentWillUnmount () {
		if(this.state.stage !== null){
			
			this.state.stage.removeAllEventListeners();
			this.state.stage.removeAllChildren();
			this.state.stage.canvas = null;
			this.state.stage = null;
			this.setState({stage: null});
		}
	}

	initImageLoad = () =>  {
		this.loadImage();
		return '';
	}

	loadImage = () => {
		let image = new Image();
		
		image.src = this.props.imageRef;
		image.onload = this.updateImage;
	}
	updateImage = (event) => {
		var image = event.target;
		if(image.src.includes(this.props.number)){
			var xratio = this.props.size / image.width,
				yratio = this.props.size / image.height;

			var scale = Math.min(xratio, yratio);

			var bitmap = new createjs.Bitmap(image);
			
			bitmap.scaleX = bitmap.scaleY = scale;
			bitmap.x = 0;
			bitmap.y = 0;
			if(this.state.stage){
				this.state.stage.addChild(bitmap);
				this.state.stage.update();
			}
		}
	}

	render(){
	

		return (
			<canvas id={this.props.id} height={this.props.size} width={this.props.size} >
				{this.state.stage !== null ?  this.initImageLoad() : ''}
			</canvas>
		)
	}
	
}

export default withFirebase(SkillImgRenderer);