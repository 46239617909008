import React, { ReactNode } from 'react';
import Media, { QueryResults } from 'react-media';
import { Route, Switch } from 'react-router-dom';

export interface MediaMapperProps {
    children?: React.ReactNode;
}

export type MediaProp = 'desktop' | 'tablet' | 'mobile';

export const mediaQueries = {
    mobile: '(max-width: 599px)',
	tablet: '(min-width: 600px) and (max-width: 1199px)',
	desktop: '(min-width: 1200px)',
};

const queryIndex:{[key:string]: number} = {
	'mobile': 0,
	'tablet': 1,
	'desktop': 2
}

const MediaMapper = (mapperProps: MediaMapperProps) => {
    const { children } = mapperProps;
    const getChild = (matches: QueryResults): ReactNode => {
		const len = React.Children.toArray(children).length;
		const indexKey:string = Object.keys(matches).filter((k:string) => { return !!matches[k]})[0];
		if(indexKey){
        	const index = queryIndex[indexKey]
			return React.Children.toArray(children)[index];
		}
		else {
			return React.Children.toArray(children)[2];
		}
    };

    return (
        <Switch>
            <Route path="/">
                <Media queries={mediaQueries}>{(matches) => getChild(matches)}</Media>
            </Route>
        </Switch>
    );
};
export default MediaMapper;
