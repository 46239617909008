import {Stats} from '../../../../models';

export const getGen1BaseStats = (stats:Stats, evoLevel:number, rarity:number) => {
	modifyByEvolution(stats, evoLevel, rarity)
}


export const update = (stats:Stats, evoLevel:number, rarity:number,  level:number, reborn:boolean=false) => {
	let stat:Stats = modifyByEvolution(stats, evoLevel, rarity);
	stat = updateByLevel(stat, level);
	if(reborn){
		stat = rebornUpdate(stat);
	}

	return stat;
}
const modifyByEvolution = (stats:Stats, evoLevel:number, rarity:number) => {
	const n = Object.assign({}, stats);
	if(rarity === 6){
		n.hp = Math.floor(n.hp + ((0.2) * n.hp));
		n.speed = Math.floor(n.speed + ((0.2) * n.speed));
		n.defense = Math.floor(n.defense + ((0.2) * n.defense));
		n.attack = Math.floor(n.attack + ((0.2) * n.attack));
		n.stamina += 30;
	}

	else {
		n.hp = Math.floor(n.hp + ((0.1 * evoLevel) * n.hp));
		n.speed = Math.floor(n.speed + ((0.1* evoLevel) * n.speed));
		n.defense = Math.floor(n.defense + ((0.1* evoLevel) * n.defense));
		n.attack = Math.floor(n.attack + ((0.1* evoLevel) * n.attack));
		n.stamina += 15 * evoLevel;
	}

	return n;
}

const updateByLevel = (stats:Stats, level:number) => {
	let n = Object.assign({}, stats);
	n.hp = Math.floor(25 + level * 4 * (n.hp  / 100));
	n.attack = Math.floor(6 + level * 2.5 * (n.attack / 100));
	n.defense = Math.floor(3 + level * 1.55 * (n.defense / 100));
	n.speed = Math.floor(1 + level * (n.speed / 100));
	return n;
}

const rebornUpdate = (stats:Stats) => {
	let n = Object.assign({}, stats);
	n.hp = Math.floor(n.hp + ((0.2) * n.hp));
	n.speed = Math.floor(n.speed + ((0.2) * n.speed));
	n.defense = Math.floor(n.defense + ((0.2) * n.defense));
	n.attack = Math.floor(n.attack + ((0.2) * n.attack));
	n.stamina += 50;

	return n;
}