import Firebase from "../../api/firebase"
import { Action } from "."

export const getAllNexomon = (firebase:Firebase) => {
	return {
		type: Action.firebase.getAllNexomon.type,
		payload: firebase.getData()
	}
}

export const getAllGen1Nexomon = (firebase:Firebase) => {
	return {
		type:Action.firebase.getAllNexomonGen1.type,
		payload: firebase.getDataGen1
	}
}

export const setActiveGEN = (generation: "GEN_ONE" | "GEN_TWO") => {
	return {
		type: Action.firebase.setActiveGeneration.type,
		payload: generation
	}
}

export const setActiveNexomon = (id:number) => {
	return {
		type: Action.firebase.setActiveNexomon.type,
		payload: id
	}
}

export const getAllSkills = (firebase:Firebase) => {
	return {
		type: Action.firebase.getAllSkills.type, 
		payload: firebase.getAllSkills()
	}
}

export const getAllItems = (firebase:Firebase) => {
	return {
		type: Action.firebase.getAllItems.type,
		payload: firebase.getAllItems()
	}
}

export const getAllImages = (firebase:Firebase) => {
	return {
		type: Action.firebase.getAllImages.type,
		payload: firebase.getAllImages()
	}
}