import {Status} from './status-effect.component';

const statusEffects: Status[] = [
	{
		id: "sleep",
		description: "Affected nexomon cannot use any skills. It cannot evade attacks either.",
		skills:[
			{
				id: 132,
				chance: 20,
				duration: 
					{min: 1, max: 5}

			},
			{
				id: 168,
				chance: 30,
				duration: 
					{min: 1, max: 5}

			},
			{
				id: 181,
				chance: 35,
				duration: 
					{min: 1, max: 5}

			},
		]	
},
{
	id: "paralyze",
	description: "Affected Nexomon has a 30% chance of failing to use a skill.",
	skills: 
	[
		{
			id: 8,
			chance: 20,
			duration: 
				{min: -1, max: -1}

		},
		{
			id: 42,
			chance: 30,
			duration: 
				{min: -1, max: -1}

		},
		{
			id: 43,
			chance: 10,
			duration: 
				{min: -1, max: -1}

		},
		{
			id: 102,
			chance: 20,
			duration: 
				{min: -1, max: -1}

		},
		{
			id: 125,
			chance: 100,
			duration: 
				{min: -1, max: -1}

		},
	],
},
{
	id: "burn",
	description: "Affected Nexomon receives damage each turn, at a rate of 8% max Health Points.",
	skills:  [
	{
		id: 16,
		chance: 30,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 17,
		chance: 10,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 24,
		chance: 20,
		duration: 
			{min: -1, max: -1}

	}
]},
{
	id: "freeze",
	description: "Affected Nexomon cannot use any skills, except for a Fire Skill. Using a Fire Skill will also remove the status effect. Each turn has a 30% chance to thaw.",
	skills:  [
	{
		id: 28,
		chance: 35,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 62,
		chance: 10,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 67,
		chance: 20,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 72,
		chance: 30,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 77,
		chance: 10,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 143,
		chance: 10,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 144,
		chance: 20,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 155,
		chance: 30,
		duration: 
			{min: -1, max: -1}

	}
	
]},
{
	id: "poison",
	description: "Affected Nexomon receives damage each turn at a rate of 10% max Health Points.",
	skills:  [
	
	{
		id: 9,
		chance: 10,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 85,
		chance: 30,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 86,
		chance: 20,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 114,
		chance: 35,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 119,
		chance: 20,
		duration: 
			{min: -1, max: -1}

	},
]},
{
	id: "demotivated",
	description: "Affected Nexomon has a 50% chance of failing to use a skill.",
	skills:  [
	{
		id: 10,
		chance: 10,
		duration: 
			{min: 1, max: 2}

	},
	{
		id: 147,
		chance: 30,
		duration: 
			{min: 2, max: 3}

	},
	{
		id: 152,
		chance: 20,
		duration: 
			{min: 1, max: 2}

	},
	{
		id: 170,
		chance: 30,
		duration: 
			{min: 2, max: 4}

	}
]},
{
	id: "bind",
	description: "Tamer cannot swap affected Nexomon out for another Nexomon. Affected Nexomon receives damage each turn at a rate of 8% max Health.",
	skills: [
	{
		id: 7,
		chance: 35,
		duration: 
			{min: 1, max: 5}

	},
	{
		id: 27,
		chance: 30,
		duration: 
			{min: 1, max: 5}

	},
	{
		id: 29,
		chance: 35,
		duration: 
			{min: 1, max: 5}

	},
	{
		id: 30,
		chance: 30,
		duration: 
			{min: 1, max: 5}

	},
	{
		id: 56,
		chance: 10,
		duration: 
			{min: 1, max: 5}

	},
	{
		id: 89,
		chance: 10,
		duration: 
			{min: 1, max: 5}

	},
]},
{
	id: "confusion",
	description: "Affected Nexomon has a 35% chance of failing to use a skill. If this happens, it will receive damage of 8% max Health.",
	skills:  [
	{
		id: 40,
		chance: 40,
		duration: 
			{min: 1, max: 3}

	},
	{
		id: 44,
		chance: 30,
		duration: 
			{min: 1, max: 5}

	},
	{
		id: 47,
		chance: 10,
		duration: 
			{min: 1, max: 3}

	},
	{
		id: 55,
		chance: 30,
		duration: 
			{min: 1, max: 5}

	},
	{
		id: 74,
		chance: 35,
		duration: 
			{min: 1, max: 5}

	},
	{
		id: 151,
		chance: 10,
		duration: 
			{min: 1, max: 5}

	},
	{
		id: 156,
		chance: 10,
		duration: 
			{min: 1, max: 5}

	}
]},
{
	id: "hex",
	description: "Affected Nexomon receives damage each turn at a rate of 12% max Health.",
	skills: [
	{
		id: 124,
		chance: 15,
		duration: 
			{min: -1, max: -1}

	}
]},
{
	id: "sick",
	description: "Affected Nexomon cannot receive any healing.",
	skills: [
	{
		id: 79,
		chance: 30,
		duration: 
			{min: 4, max: 4}
	}
]},
{
	id: "puzzled",
	description: "Affected Nexomon can only use the same skill as the last one, otherwise they lose their turn.",
	skills: [
	{
		id: 48,
		chance: 35,
		duration: 
			{min: 1, max: 3}

	},
	{
		id: 157,
		chance: 30,
		duration: 
			{min: 1, max: 3}

	},
	{
		id: 159,
		chance: 10,
		duration: 
			{min: 1, max: 3}

	},
	{
		id: 165,
		chance: 25,
		duration: 
			{min: 1, max: 3}

	}
]},
{
	id: "leech",
	description: "Affected Nexomon has 10% of max Health stolen by enemy Nexomon.",
	skills: [
	{
		id: 2,
		chance: 40,
		duration: 
			{min: 2, max: 5}

	},
]},

{
	id: "inception",
	description: "If affected Nexomon is asleep, the Nexomon receives damage at a rate of  15% max Heath each turn. This effect is cured if the Nexomon is no longer asleep. ",
	skills: [
	{
		id: 142,
		chance: 100,
		duration: 
			{min: -1, max: -1}

	},
]},

{
	id: "cursed",
	description: "Affected Nexomon has all skills become 'not effective'.",
	skills: [
	{
		id: 175,
		chance: 50,
		duration: 
			{min: 2, max: 5}

	},
]},
{
	id: "provoked",
	description: "Affected Nexomon cannot inflict status effects.",
	skills: [
	{
		id: 128,
		chance: 30,
		duration: 
			{min: 2, max: 5}

	}
]},
{
	id: "nervous",
	description: "If affected Nexomon tries to use the same Skill as the previous round it has a 60% chance of failing. ",
	skills: [
	{
		id: 126,
		chance: 25,
		duration: 
			{min: 2, max: 5}

	}
]},
{
	id: "frail",
	description: "Affected Nexomon receives 30% extra damage from Normal Element attacks.",
	skills: [
	{
		id: 75,
		chance: 20,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 93,
		chance: 30,
		duration: 
			{min: -1, max: -1}

	},
	{
		id: 97,
		chance: 10,
		duration: 
			{min: -1, max: -1}

	}
]},
{
	id: "recover",
	description: "Affected Nexomon has 8% Heath restored each turn.",
	skills: [
	{
		id: 88,
		chance: 100,
		duration: 
			{min: -1, max: -1}

	}
]},
{
	id: "survive",
	description: "Affected Nexomon will not drop below 1 HP for the duration of this effect.",
	skills: [
	{
		id: 76,
		chance: 100,
		duration: 
			{min: 2, max: 2}

	},
]},
{
	id: "planted",
	description: "Affected Nexomon can neither be swapped or escape from the battle.",
	skills: [
	{
		id: 162,
		chance: 100,
		duration: 
			{min: -1, max: -1}

	}
]},
{
	id: "invincible",
	description: "Affected Nexomon receives no damage from incoming attacks.",
	skills: [
	{
		id: 167,
		chance: 100,
		duration: 
			{min: 1, max: 1}

	},
]}];

export default statusEffects;
