import React, { useEffect } from 'react';
import './list-card.styles.scss';
import ImageBackGround from '../../animated/animated-background/image-background.component.js';
import { Nexomon, RARITY, ImageRef } from '../../../models';
import elementType from '../../../../assets/types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { uploadPreviewImages } from '../../../api/helpers/updateImagePreviews';

export interface ListCardProps {
	nexomon: Nexomon;
	activeGeneration: string;
	images: {[key:string]: ImageRef} | null;
	handleClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	mediaType:string;
};

const formatNumber = (num:number) => {
	if(num < 10) {
		return `00${num}`;
	}
	else if (num < 100) {
		return `0${num}`;
	}
	else {
		return num;
	}
}

const rarityCode = (num:RARITY | string) => {
	switch(num) {
		case RARITY.Common: return 'C';
		case RARITY.Uncommon: return 'UC';
		case RARITY.Rare: return 'R';
		case RARITY["Very Rare"]: return 'MR';
		case RARITY.Special: return 'S';
		case RARITY.Legendary: return 'L';
		case RARITY.Ultimate: return 'U';
		default: return 'U';
	}
}

const rarityToText = (num:RARITY | string) => {
	switch(num) {
		case RARITY.Common: return 'common';
		case RARITY.Uncommon: return 'uncommon';
		case RARITY.Rare: return 'rare';
		case RARITY["Very Rare"]: return 'mega rare';
		case RARITY.Special: return 'starter';
		case RARITY.Legendary: return 'legendary';
		case RARITY.Ultimate: return 'ultimate';
		default: return '';
	}
}

const ListCard = (props:ListCardProps) => {
	const { activeGeneration, images, mediaType, nexomon, handleClick } = props;
	
	if(nexomon){
	return (
		<div className={clsx(`list-card-container nexomon-${nexomon.number}`, mediaType)} onClick={handleClick ? handleClick : undefined}>
			<div className={clsx('list-card', mediaType)} >
				<div className={clsx('list-card-title', mediaType)}>
					<span className={clsx('name', {['shifted']: nexomon.name.split('_')[0].length >= 12})}>{nexomon.name.split('_')[0]}</span>
					<span className={'number'}>{formatNumber(nexomon.number)}</span>
				</div>
				<div className={clsx('list-card-header', mediaType, `rarity-${nexomon.rarity}`, {['gen-1']: activeGeneration === 'GEN_ONE'})}>
					{ images && activeGeneration === 'GEN_TWO' ? (mediaType === 'mobile' ?
						<ImageBackGround activeGeneration={activeGeneration} id={nexomon.name.toLowerCase()} name={nexomon.name.toLowerCase()} height={125} width={125} noShadow={true} size={'circular'} imageRef={images[nexomon.name.toLowerCase()]['circular']}/>
						:
						<ImageBackGround activeGeneration={activeGeneration} id={nexomon.name.toLowerCase()} name={nexomon.name.toLowerCase()} height={175} width={175} noShadow={true} size={'circular'} imageRef={images[nexomon.name.toLowerCase()]['circular']}/>
					) : images ? (mediaType === 'mobile' ?
						<ImageBackGround activeGeneration={activeGeneration} id={nexomon.name.toLowerCase()} name={nexomon.name.toLowerCase()} height={125} width={125} noShadow={true} size={'small'} imageRef={images[nexomon.name.toLowerCase()]['small']}/>
						:
						<ImageBackGround activeGeneration={activeGeneration} id={nexomon.name.toLowerCase()} name={nexomon.name.toLowerCase()} height={175} width={175} noShadow={true} size={'small'} imageRef={images[nexomon.name.toLowerCase()]['small']}/>
					) : null }
				</div>
				{ nexomon?.rarity && nexomon?.element ?
				<div className={clsx('list-card-footer', mediaType)} >
					<div className={clsx('type-container', mediaType)}>
						
						<div className={clsx('type-icon', mediaType)}>
							<img src={elementType(nexomon.element)} />
						</div>
						<div className={clsx('type-title', mediaType)}>
							{nexomon?.element}
						</div>
					</div>

					<div className={clsx('rarity-container', mediaType)}>
						<div className={clsx('rarity-box', mediaType,{[`rarity-${nexomon?.rarity}`]: nexomon?.rarity})}>
							{rarityCode(nexomon?.rarity ? nexomon.rarity : '')}
						</div>
						<div className={clsx('rarity-title', mediaType)}>
							{rarityToText(nexomon?.rarity)}
						</div>
					</div>
				</div> : null}
			</div>
		</div>
	
	)}
	else {
		return null;
	}
}

export default ListCard;