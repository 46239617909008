import React from 'react';
import clsx from 'clsx';
import './walkthrough.styles.scss';
import { AdSenseDesktopInList, AdSenseMobileAboveTheFold, AdSenseMobileBelowTheFold } from '../../../common/components/adsense-components/adsense-components';
import { useHistory } from 'react-router-dom';



export interface WalkthroughProps {
	mediaType:string;
}

const Walkthrough = (props: WalkthroughProps) => {
	const { mediaType } = props;

	const history = useHistory();

	const handleClick = (event:React.MouseEvent | React.TouchEvent) => {event.preventDefault();};
	return (
		<div className={clsx('walkthrough-container', mediaType)}>
			<div className={clsx('walkthrough-title')}>
				Walkthrough Guide
			</div>
			<div className={'details'}>
			<div className={clsx('walkthrough-list')}>
				<div>Introduction</div>
				<a href={'#understanding-walkthrough-1'}>1. Understanding Walkthrough Part 1</a>
				<a href={'#understanding-walkthrough-2'}>2. Understanding Walkthrough Part 2</a>
				<div>A Hero's Legacy</div>
				<a href={'#ahl_1'}>1. A Legend Begins</a>
			</div>
			<div className={clsx('walkthrough-list')}>
				<div>Index</div>
				<a href={'#orphanage'}>1. Orphanage</a>
			</div>
			</div>
			<div className={clsx('walkthrough-images', mediaType)} >
				<img id={'understanding-walkthrough-1'} onTouchEndCapture={handleClick} onClick={handleClick} width="100%" height="auto" src={'https://us-central1-nexopad-2.cloudfunctions.net/api/images/skills/walkthrough/page_1'} alt={'Nexopad Nexomon Extinction Walkthrough Howto Guide'} />
				{mediaType === 'mobile' ? (Math.ceil(Math.random() * 2) === 1 ? <AdSenseMobileAboveTheFold  path={history.location.pathname} /> : <AdSenseMobileBelowTheFold  path={history.location.pathname} />) : <AdSenseDesktopInList  path={history.location.pathname}/> }
				<img id={'understanding-walkthrough-2'} onTouchEndCapture={handleClick} onClick={handleClick} width="100%" height="auto" src={'https://us-central1-nexopad-2.cloudfunctions.net/api/images/skills/walkthrough/page_2'} alt={'Nexopad Nexomon Extinction Walkthrough Howto Guide Book'} />
				{mediaType === 'mobile' ? (Math.ceil(Math.random() * 2) === 1 ? <AdSenseMobileAboveTheFold  path={history.location.pathname} /> : <AdSenseMobileBelowTheFold  path={history.location.pathname} />) : <AdSenseDesktopInList  path={history.location.pathname}/> }
				<img id={'ahl_1'} onTouchEndCapture={handleClick} onClick={handleClick} width="100%" height="auto" src={'https://us-central1-nexopad-2.cloudfunctions.net/api/images/skills/walkthrough/AHL_page_1_full'} alt={'Nexopad Nexomon Extintion Walkthrough Orphanage Heros Journey Beginning'} />
				{mediaType === 'mobile' ? (Math.ceil(Math.random() * 2) === 1 ? <AdSenseMobileAboveTheFold  path={history.location.pathname} /> : <AdSenseMobileBelowTheFold  path={history.location.pathname} />) : <AdSenseDesktopInList  path={history.location.pathname}/> }
				<img id={'orphanage'} onTouchEndCapture={handleClick} onClick={handleClick} width="100%" height="auto" src={'https://us-central1-nexopad-2.cloudfunctions.net/api/images/skills/walkthrough/orphanage_full'} alt={'Nexopad Nexomon Extintion Walkthrough Orphanage Index'} />
				{mediaType === 'mobile' ? (Math.ceil(Math.random() * 2) === 1 ? <AdSenseMobileAboveTheFold  path={history.location.pathname} /> : <AdSenseMobileBelowTheFold  path={history.location.pathname} />) : <AdSenseDesktopInList  path={history.location.pathname}/> }
			</div>
		</div>
	)
}

export default Walkthrough;