import RouteContainer, {} from './route-container.component';
import { connect } from 'react-redux';
import { State } from '../../common/models';
import { Dispatch } from 'redux';
import Firebase from '../../common/api/firebase';
import { getAllNexomon, getAllGen1Nexomon, getAllSkills, getAllItems, getAllImages } from '../../common/state/actions/firebase.actions';


const mapStateToProps = (state:State) => {
	return {
		nexomon: state.firebase?.nexomon ? state.firebase?.nexomon : [],
		nexomonGen1: state.firebase?.nexomonGen1 ? state.firebase?.nexomonGen1 : [],
		skills: state.firebase?.skills ? state.firebase?.skills : null,
		items: state.firebase?.items ? state.firebase?.items : null,
		user: state.data?.user,
		images: state.firebase?.images ? state.firebase?.images : null
	}
}

const mapDispatchToProps = (dispatch:Dispatch) => {
	return {
		getNexomon: (firebase:Firebase) => {
			dispatch(getAllNexomon(firebase));
		},
		getNexomonGen1: (firebase:Firebase) => {
			dispatch(getAllGen1Nexomon(firebase));
		},
		getSkills: (firebase:Firebase) => {
			dispatch(getAllSkills(firebase));
		},
		getItems: (firebase:Firebase) => {
			dispatch(getAllItems(firebase));
		},
		getImages: (firebase:Firebase) => {
			dispatch(getAllImages(firebase));
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(RouteContainer);