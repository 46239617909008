import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './common/state/store';
import NavBar from './browser/nav-bar/nav-bar';
import RouteContainer from './browser/route-container/route-container';
import Firebase, { FirebaseContext } from './common/api/firebase';
import Footer from './browser/footer/footer.component';
import 'normalize.css';
function App() {
	
	return (
		<Provider store={store}>
			<FirebaseContext.Provider value={new Firebase()}>
				<Router>
					<NavBar />
					<RouteContainer /> 
					<Footer /> 
				</Router>
			</FirebaseContext.Provider>
		</Provider>
	);
}

export default App;
