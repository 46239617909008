import React from 'react';
import { ElementIcon } from '../../element-icon/element-icon';
import {elements, elementComparisons, ElementCompare} from './typeComparison';
import { Element } from '../../../models';
import './type-chart.styles.scss';
import ElementDisplayComponent from '../card/element-display/element-display.component';
import TypeComparison from './type-comparison.component';
import clsx from 'clsx';
import { AdSenseDesktopInList, AdSenseMobileAboveTheFold, AdSenseMobileBelowTheFold } from '../../adsense-components/adsense-components';
import { useHistory } from 'react-router-dom';

export interface ElementChartProps {
	mediaType:string;
}

const ElementChart = (props:ElementChartProps) => {
	const {mediaType} = props;
	const history = useHistory();

	return (
		<div className={clsx('element-chart-container', mediaType)}>
			<div className={'chart-container'}>
				<div className={'element-description'}>
					All nexomon have a distinguishing feature about them that can be
					described by an affiliation to elements.  Each element has its own
					unique effects and defects.
				</div>
				<div className={'link-group'}>
					<div>
						<a href={'#normal'} ><ElementDisplayComponent showTitle={false} element={'normal'} mediaType={mediaType}/></a>
					</div>
					<div>
						<a href={'#fire'} ><ElementDisplayComponent showTitle={false} element={'fire'} mediaType={mediaType} /></a>
					</div>
					<div>
						<a href={'#water'} ><ElementDisplayComponent showTitle={false} element={'water'} mediaType={mediaType} /></a>
					</div>
					<div>
						<a href={'#plant'} ><ElementDisplayComponent showTitle={false} element={'plant'} mediaType={mediaType} /></a>
					</div>
					<div>
						<a href={'#mineral'} ><ElementDisplayComponent showTitle={false} element={'mineral'} mediaType={mediaType} /></a>
					</div>
					<div>
						<a href={'#wind'} ><ElementDisplayComponent showTitle={false} element={'wind'} mediaType={mediaType} /></a>
					</div>
					<div>
						<a href={'#electric'} ><ElementDisplayComponent showTitle={false} element={'electric'} mediaType={mediaType} /></a>
					</div>
					<div>
						<a href={'#psychic'} ><ElementDisplayComponent showTitle={false} element={'psychic'} mediaType={mediaType} /></a>
					</div>
					<div>
						<a href={'#ghost'} ><ElementDisplayComponent showTitle={false} element={'ghost'} mediaType={mediaType} /></a>
					</div>

					<div className='hint'>
						click an element to jump to it's comparison chart
					</div>
				</div>
				{mediaType !== 'mobile' ? 
					<AdSenseDesktopInList  path={history.location.pathname}/>
					: <AdSenseMobileAboveTheFold path={history.location.pathname} />}
			</div>
			<div className={'element-chart'}>
				<div className={'element-title'}>element comparison chart</div>
				<div className={'element-description'}>
					<div>
						Elements have various strengths and weaknesses when they come into
						contact with one another. This chart displays how those comparisons work.
					</div>
					<div className={'element-description'} style={{backgroundColor: '#f4f4f4', padding: '10px', borderRadius: '8px', margin: '5px'}}>
						<div style={{marginTop: '10px'}}>
						<span style={{fontSize: '16px', lineHeight: '20px', fontWeight: 'bold', color: '#3a3a3a'}}>Strong Against:</span>
							<div style={{color: '#3a3a3a'}}>
								When one element is stronger than another it deals increased damage.
							</div>
							<div style={{color: '#3a3a3a'}}>
								Damage increase of 125 %.
							</div>
						</div>
						<div style={{marginTop: '10px'}}>
						<span style={{fontSize: '16px', lineHeight: '20px', fontWeight: 'bold', color: '#3a3a3a'}}>Weak Against:</span>
							<div style={{color: '#3a3a3a'}}>
								When one element is weaker than another it deals reduced damage.
							</div>
							<div style={{color: '#3a3a3a'}}>
								Damage reduction of 65 %.
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className='defender-column'>

					</div>
					{
						elementComparisons.map((element:ElementCompare) => {
							return (
									
									<div id={element.element} className='element-row' title={element.element.charAt(0).toUpperCase() + element.element.slice(1)} >	
										<TypeComparison mediaType={mediaType} elementType={element.element} strongerThan={element.strongAgainst} weakerThan={element.weakAgainst} />
									</div>
							)
						})
						
					}
				</div>
			</div>
			{mediaType !== 'mobile' ? 
				<AdSenseDesktopInList path={history.location.pathname} />
				: 
				<AdSenseMobileBelowTheFold path={history.location.pathname}/>
			}
		</div>
	);
}

export default ElementChart;