
const ActionBuilder = (value:string) => {
	
	return {
		type: value,
		pending: `${value}_PENDING`,
		fulfilled: `${value}_FULFILLED`,
		rejected: `${value}_REJECTED`,
	}

}

export const Action = {
	data: {
		dataLoaded: ActionBuilder('DATA_LOADED'),
		setCookieAccepted: ActionBuilder('SET_COOKIE_ACCEPTED'),
		updateUser: ActionBuilder('SET_USER'),
	},
	firebase: {
		getAllNexomon: ActionBuilder('GET_ALL_NEXOMON'),
		getAllNexomonGen1: ActionBuilder('GET_ALL_GEN_ONE_NEXOMON'),
		setActiveGeneration: ActionBuilder('SET_ACTIVE_GENERATION'),
		setActiveNexomon: ActionBuilder('SET_ACTIVE_NEXOMON'),
		getAllSkills: ActionBuilder('GET_ALL_SKILLS'),
		getAllItems: ActionBuilder('GET_ALL_ITEMS'),
		getAllImages: ActionBuilder('GET_ALL_IMAGES'),
	}
}

