import Nexopedia, { StateProps, DispatchProps, OwnProps, NexopediaComponentProps} from './nexopedia.component';
import { State, Nexomon } from '../../common/models';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Firebase, { withFirebase } from '../../common/api/firebase';
import { getAllNexomon, getAllGen1Nexomon, setActiveGEN } from '../../common/state/actions/firebase.actions';

const mapStateToProps = (state:State):StateProps => {
	return {
		nexomon: state.firebase?.nexomon ? state.firebase.nexomon : [] as Nexomon[],
		nexomonGen1: state.firebase?.nexomonGen1 ? state.firebase.nexomonGen1 : [] as Nexomon[],
		activeGeneration: state.firebase?.activeGeneration ? state.firebase?.activeGeneration : "GEN_TWO",
		activeNexomon: state.firebase?.activeNexomon ? state.firebase?.activeNexomon : null,
		images: state.firebase?.images ? state.firebase?.images : null
	}
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => {
	return {
		getNexomon: (firebase:Firebase) => {
			dispatch(getAllNexomon(firebase))
		},
		getNexomonGen1 : (firebase:Firebase) => {
			dispatch(getAllGen1Nexomon(firebase));
		},
		updateGeneration: (generation: "GEN_TWO" | "GEN_ONE") => {
			dispatch(setActiveGEN(generation));
		}
	}
}

const mergeProps = (stateProps:StateProps, dispatchProps:DispatchProps, ownProps:OwnProps):NexopediaComponentProps => {
	const {nexomon} = stateProps;
	return {
		...stateProps,
		...dispatchProps,
		...ownProps,
		getNexomonById: (id:number):Nexomon | null => {
			const nexo = nexomon.find((nex:Nexomon) => {
				return nex.number === id;
			});
			if(nexo){
				return nexo;
			}
			else {
				return null;
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withFirebase(Nexopedia));