import React from 'react';
import createjs from 'createjs-module';
import { withFirebase} from '../../../../api/firebase';
import backgroundAsset from '../../../../../assets/modified_background.png';
class GenerateElementBackground extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			imageContainer: new createjs.Container(),
		}	
	}
	componentDidMount(){
		this.props.stage.addChild(this.state.imageContainer);
		
		this.loadImage();
		this.state.imageContainer.x = 0;
		this.state.imageContainer.y = 0;

		//this.state.imageContainer.x = 280;
		//this.state.imageContainer.y = -220;
		this.state.imageContainer.width = this.props.width;
		this.state.imageContainer.height = this.props.height;
		//this.state.imageContainer.rotation = 45;
		createjs.Ticker.setFPS(60);
		createjs.Ticker.addEventListener('tick', this.updateBackground.bind(this));
	}

	loadImage = () => {
		let image = new Image();
		image.src = backgroundAsset;
		image.onload = this.updateImage;
	}

	updateImage = (event) => {
		var image = event.target;

		var bitmap = new createjs.Bitmap(image);
	
    	bitmap.x = 0;
		bitmap.y = 0;
		if(this.state.imageContainer.children.length > 0){
			bitmap.x = 520;
		}
		bitmap.followerAdded = false;
		this.state.imageContainer.addChild(bitmap);
		this.state.imageContainer.mouseChildren = false;

		this.props.stage.update();
	}

	updateBackground = () => {
		if(this.state.imageContainer && this.state.imageContainer.children){
			this.state.imageContainer.children.forEach((child) => {
				child.x -= 0.7;
				if(child.x < -680 && !child.followerAdded){
					this.loadImage();
					child.followerAdded = true;
				}
				if(child.x < -1220 && child.followerAdded){
					this.state.imageContainer.removeChild(child);
				}
			});
		}
		this.props.stage.update();
		
	}



	render(){
		return null;
	}
}

export default withFirebase(GenerateElementBackground);