import React from 'react';
import './evolution.styles.scss';
import { Evolution, RARITY, ImageRef } from '../../../../models';
import ImageBackgroundComponent from '../../../animated/animated-background/image-background.component';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { setActiveNexomon } from '../../../../state/actions/firebase.actions';

export interface EvolutionProps {
	evolutions: Evolution[];
	nexoId: number;
	name:string;
	rarity: RARITY | undefined;
	activeGeneration: string;
	mediaType:string, 
	images: {[key:string]: ImageRef} | null;
}

const sortByNuber = (a:Evolution, b:Evolution) => { return a.number > b.number ? 1 : (a.number < b.number ? -1 : 0)};

const EvolutionComponent = (props: EvolutionProps) => {
	const { activeGeneration, images, evolutions, nexoId, rarity, mediaType} = props;

	const history = useHistory();
	const dispatch = useDispatch();

	const handleClick = (event:React.MouseEvent<HTMLAnchorElement>, evo:Evolution):void => {
		if(event.button === 0 || event.button === 1){
			event.preventDefault();
			dispatch(setActiveNexomon(evo.number));
			history.push(`/nexopedia/${evo.name}`)
		}
	}
	if(evolutions && images) {
	return (
		<div className={clsx('evolution-container', mediaType)}>
			<div className={clsx('evolution-title', mediaType)}>
				evolution
			</div>
			<div className={clsx('evolution-body', mediaType)}>
				<div className={clsx('items-container', mediaType, {['shift']: evolutions.length === 2, ['center']: evolutions.length === 1})}>
					{evolutions && evolutions.length ? (
						evolutions.sort(sortByNuber).map((e:Evolution, index:number) => {
							return (
								<a key={e.name} href={`/nexopedia/${e.name}`} onClick={(event:React.MouseEvent<HTMLAnchorElement>) => {handleClick(event, e)}}>
									<div className={clsx('evo-item', mediaType, {['shift']: evolutions.length === 2 })} key={e.number} >
										{/* <div className={'evo-item-title'}>
											<span>{e.name}</span>
											<span>{formatNumber(e.number)}</span>
										</div> */}
										<div className={clsx('canvas-container', mediaType, {['gen-2']: activeGeneration === 'GEN_TWO'})}>
											{mediaType !== 'mobile' ? 
												(activeGeneration === 'GEN_TWO' ?
													<ImageBackgroundComponent activeGeneration={activeGeneration} classes={clsx('no-top', `rarity-${rarity}`, {['grey']: nexoId !== e.number })} height={75} width={75} size={'circular'} name={e.name} id={`nexomon-evolution-image_${activeGeneration}_${e.number}`} noShadow={true} imageRef={images[e.name.toLowerCase()]['circular']}/>
													:
													<ImageBackgroundComponent activeGeneration={activeGeneration} classes={clsx('no-top', `rarity-${rarity}`, {['grey']: nexoId !== e.number })} height={90} width={90} size={'tiny'} name={e.name} id={`nexomon-evolution-image_${activeGeneration}_${e.number}`} noShadow={true} imageRef={images[e.name.toLowerCase()]['tiny']}/>
												)	
												:
												(activeGeneration === 'GEN_TWO' ?
													<ImageBackgroundComponent activeGeneration={activeGeneration} classes={clsx('no-top', `rarity-${rarity}`, {['grey']: nexoId !== e.number })} height={75} width={75} size={'circular'} name={e.name} id={`nexomon-evolution-image_${activeGeneration}_${e.number}`} noShadow={true} imageRef={images[e.name.toLowerCase()]['circular']}/>
													:
													<ImageBackgroundComponent activeGeneration={activeGeneration} classes={clsx('no-top', `rarity-${rarity}`, {['grey']: nexoId !== e.number })} height={75} width={75} size={'tiny'} name={e.name} id={`nexomon-evolution-image_${activeGeneration}_${e.number}`} noShadow={true} imageRef={images[e.name.toLowerCase()]['tiny']}/>
												)
											}
										</div>
										{ index + 1 !== evolutions.length ?
											(<div className={clsx('evo-item-level', mediaType, {['gen-2']: activeGeneration === 'GEN_TWO'})}>
												<span>LVL</span>
												<span>{e.level}</span>
											</div>)
											: null
										}
									</div>
								</a>
							)})
						) : null }
				</div>
			</div>
		</div>
	)
	}
	else {
		return null;
	}
};

export default EvolutionComponent;