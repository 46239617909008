import React, { useState } from 'react';
import clsx from 'clsx';
import MediaMapper, { MediaProp } from "../../common/components/media-mapper/media-mapper.comonents";
import icon from '../../assets/nexopad2_icon.png';
import menuBar from '../../assets/menu_bar.png';
import './nav-bar.styles.scss';
import { useHistory } from 'react-router-dom';
import icons from '../../assets/icons';

export interface StateProps {

}

export interface DispatchProps {

}

export interface OwnProps {

}

export interface NavbarProps {

}

export const Navbar = (props:NavbarProps & {mediaType: MediaProp}) => {
	const { mediaType } = props;

	const [showMenu, setShowMenu] = useState(false);
	const history = useHistory();

	if(mediaType==='desktop' || mediaType ==='tablet') {
	return (
		<div className={clsx('navbar-container')}>
			<div className={'navbar'}>
				<div className={clsx('navbar-icon')} onClick={() => { history.push('/nexopedia')}}>
					<img src={icon} alt={'Nexopad'}/>
				</div>

				<div className={'menu-items'}>

					<div className={clsx('menu-item')} onClick={() => { history.push('/')}}>
						<div className='menu-item-background'>
						<div className={'menu-item-text'}><span>Home</span></div>
						</div>
						<img src={icons('home')} alt='Home' />

					</div>

					<div className={clsx('menu-item')} onClick={() => { history.push('/nexopedia')}}>
						<div className='menu-item-background'>
						<div className={'menu-item-text'}><span>Database</span></div>
						</div>
						<img src={icons('database')} alt='Database' />

					</div>

					<div className={clsx('menu-item', 'short')} onClick={() => { history.push('/items')}}>
						<div className='menu-item-background'>
						<div className={'menu-item-text'}><span>Items</span></div>
						</div>
						<img src={icons('items')} alt='Items' />

					</div>

				

					{/* <div className={clsx('menu-item', 'disabled')} onClick={() => { history.push('/locations')}}>
						<div className='menu-item-background'>
						<div className={'menu-item-text'}><span>Locations</span></div>
						</div>
						<img src={icons('locations')} alt='Locations' />

					</div> */}

					{/* <div className={clsx('menu-item', 'short')} onClick={() => { history.push('/')}}>
						<div className={clsx('menu-item-background', 'team')}>
						<div className={'menu-item-text'}><span>Team</span></div>
						</div>
						<img src={icons('team')} alt='Team' />

					</div> */}
{/* 
					<div className={clsx('menu-item', 'medium')} onClick={() => { history.push('/')}}>
						<div className={clsx('menu-item-background')}>
						<div className={'menu-item-text'}><span>Profile</span></div>
						</div>
						<img src={icons('profile')} alt='Profile' />

					</div> */}

					<div className={clsx('menu-item')} onClick={() => { history.push('/guide/elements')}}>
						<div className={clsx('menu-item-background')}>
						<div className={'menu-item-text'}><span>Guide</span></div>
						</div>
						<img src={icons('story')} alt='Guide' />

					</div>

					<div className={clsx('menu-item', 'short')} onClick={() => { history.push('/social')}}>
						<div className='menu-item-background'>
						<div className={'menu-item-text'}><span>Social</span></div>
						</div>
						<img src={icons('social')} alt='Social' />

					</div>

				</div>
			</div>
		</div>
	)}
	else {
		return (
			<div className={clsx('navbar-container-mobile')}>
				<div className={'navbar'}>
					<div className={'menu-button'} onClick={() => {setShowMenu(!showMenu)}}>
						<img src={menuBar} alt="Menu" />
					</div>
					<div className={clsx('navbar-icon')} onClick={() => { history.push('/nexopedia')}}>
						<img src={icon} alt={'Nexopad'}/>
					</div>
				
					<div className={clsx('menu-items', {['show']: showMenu})}>

						<div className={clsx('menu-item')} onClick={() => { setShowMenu(false); history.push('/home')}}>
							<div className='menu-item-background'>
							<div className={'menu-item-text'}><span>Home</span></div>
							</div>
							<img src={icons('home')} alt='Home' />

						</div>

						<div className={clsx('menu-item')} onClick={() => { setShowMenu(false); history.push('/nexopedia')}}>
							<div className='menu-item-background'>
							<div className={'menu-item-text'}><span>Database</span></div>
							</div>
							<img src={icons('database')} alt='Database' />

						</div>

						<div className={clsx('menu-item', 'short', 'disabled')} onClick={() => { setShowMenu(false); history.push('/items')}}>
							<div className='menu-item-background'>
							<div className={'menu-item-text'}><span>Items</span></div>
							</div>
							<img src={icons('items')} alt='Items' />

						</div>

						<div className={clsx('menu-item')} onClick={() => { setShowMenu(false); history.push('/guide/elements')}}>
							<div className={clsx('menu-item-background')}>
							<div className={'menu-item-text'}><span>Guide</span></div>
							</div>
							<img src={icons('story')} alt='Guide' />
						</div>

						<div className={clsx('menu-item')} onClick={() => { setShowMenu(false); history.push('/social')}}>
							<div className={clsx('menu-item-background')}>
							<div className={'menu-item-text'}><span>Social</span></div>
							</div>
							<img src={icons('social')} alt='Social' />
						</div>

						{/* <div className={clsx('menu-item', 'short', 'disabled')} onClick={() => { history.push('/')}}>
							<div className={clsx('menu-item-background', 'team')}>
							<div className={'menu-item-text'}><span>Team</span></div>
							</div>
							<img src={icons('team')} alt='Team' />

						</div> */}
					</div>
				</div>
			</div>
		);
	}
}

const NavbarMediaMap = (props:NavbarProps) => {
	return (
		<MediaMapper>
			<Navbar {...props} mediaType={'mobile'} />
			<Navbar {...props} mediaType={'tablet'} />
			<Navbar {...props} mediaType={'desktop'} />
		</MediaMapper>
	)
}

export default NavbarMediaMap;