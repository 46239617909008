import React from 'react';
import { Route, Switch, Redirect, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import ItemView from './item-view.component';
import './items.styles.scss';
import CategoryView from './category-view.component';
import { Item, ImageRef } from '../../common/models';
import clsx from 'clsx';

export interface StateProps {
	items: Item[] | null;
	images: {[key:string]:ImageRef} | null;
	allowedCategories: string[];
}

export interface DispatchProps {

}

export interface OwnProps {
	mediaType:string;
}

export interface ItemsComponentProps extends StateProps, DispatchProps, OwnProps {
	getAllowed: (category:string, id?:string) => boolean | Item | undefined;
};

const ItemsComponent = (props:ItemsComponentProps) => {
	const { images, getAllowed, items, mediaType } = props;
	const history = useHistory();
	const match = useRouteMatch();
	if(!items){
		history.push('/');
	}
	return (
		<div className={clsx('items-page-container', mediaType)}>
			<div className={clsx('items', mediaType)}>
				<div className={clsx('items-menu', mediaType)}>
					<div className={clsx('button-group')}>
						<button onClick={() => { window.location.assign(`${window.location.protocol}//${window.location.host}/items/food`)}}>foods</button>
						<button onClick={() => { window.location.assign(`${window.location.protocol}//${window.location.host}/items/core`)}} >cores</button>
						<button onClick={() => { window.location.assign(`${window.location.protocol}//${window.location.host}/items/restoration`)}} >restoration</button>
						<button onClick={() => { window.location.assign(`${window.location.protocol}//${window.location.host}/items/shard`)}} >shards</button>
						<button onClick={() => { window.location.assign(`${window.location.protocol}//${window.location.host}/items/pet`)}} >pets</button>
						<button onClick={() => { window.location.assign(`${window.location.protocol}//${window.location.host}/items/nexotrap`)}} >nexotraps</button>
						<button onClick={() => { window.location.assign(`${window.location.protocol}//${window.location.host}/items/special`)}} >special</button>
					</div>
				</div>
				<div className={clsx('items-body', mediaType)}>
					<Switch>
						<Route path={match.path + '/:category/:id'} >
							<ItemView images={images} getAllowed={getAllowed} mediaType={mediaType} />
						</ Route>
						<Route path={match.path + '/:category'}>
							<CategoryView images={images} items={items} getAllowed={getAllowed} mediaType={mediaType} />
						</Route>
						<Route exact path={match.path }>
							<Redirect to={'/items/food'} />
						</Route>
					</Switch>
				</div>
			</div>
		</div>
	)
}

export default ItemsComponent;