import { Action } from ".";

export const setCookieAcceptance = () => {
	return {
		type: Action.data.setCookieAccepted.type,
		payload: null
	}
}

export const userStateChanged = (user:firebase.User | null) => {
	return {
		type: Action.data.updateUser.type,
		payload: user
	}
}