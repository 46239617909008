import React, { useEffect, useState } from 'react';

declare global {
    interface Window { adsbygoogle: any | []; }
}

export const AdSenseDesktopInList = (props: {path:string}) => {
	const {path} = props;
	const [state, setState] = useState(path);

	useEffect(() => {
		if(window.adsbygoogle){
			window.adsbygoogle.push({});
		}
		else {
			window.adsbygoogle = [];
			window.adsbygoogle.push({})
		}
	
	}, [])
	
	useEffect(() => {
		if(path !== state){
			setState(path);
		}
	}, [path, state])

	
		return(
	// <!-- desktop in list bar -->
			<div className={'ad-container'} style={{width: '100%', height: '150px', padding: '25px 0'}}>
				<ins className="adsbygoogle"
				id={state}
				style={{display:"inline-block",width:"800px", height:"100px", position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
				data-ad-client="ca-pub-1371660541011692"
				data-ad-slot="5737981684" />
			</div>
		);
}

export const AdSenseMobileInLine= (props:{itemView: boolean, path:string}) => {
	const {path} = props;

	const [state, setState] = useState(path);
	useEffect(() => {
		if(window.adsbygoogle){
			window.adsbygoogle.push({});
		}
		else {
			window.adsbygoogle = [];
			window.adsbygoogle.push({})
		}
	
	}, [state])

	useEffect(() => {
		if(path !== state){
			setState(path);
		}
	}, [path, state])

	return (
		<ins className='adsbygoogle'
		id={state}
		style={{display:"inline-block", width: '300px', height: '80px', marginLeft: props.itemView ?'10%': '7%'}}
		data-ad-client="ca-pub-1371660541011692"
		data-ad-slot="2798626024"
		
		/>
	);
}

export const AdSenseMobileAboveTheFold = (props: {path: string}) => {
	const {path} = props;

	const [state, setState] = useState(path);
	useEffect(() => {
		if(state === path){
			if(window.adsbygoogle){
				window.adsbygoogle.push({});
			}
			else {
				window.adsbygoogle = [];
				window.adsbygoogle.push({})
			}
		}
	
	}, [state])

	useEffect(() => {
		if(path !== state){
			setState(path);
		}
	}, [path, state])

	return (
		<div className={'ad-container-above'} style={{width: '100vw', height: '150px', padding: '25px 0'}}>
			<ins className="adsbygoogle"
				style={{display:"inline-block",width:"320px",height:"100px", position: 'relative', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}
				data-ad-client="ca-pub-1371660541011692"
				data-ad-slot="1112835806" 
			/>
		</div>
		
	);
}

export const AdSenseMobileBelowTheFold = (props: {path: string}) => {
	const {path} = props;

	const [state, setState] = useState(path);
	useEffect(() => {
		if(state === path){
			if(window.adsbygoogle){
				window.adsbygoogle.push({});
			}
			else {
				window.adsbygoogle = [];
				window.adsbygoogle.push({})
			}
		}
	
	}, [state])

	useEffect(() => {
		if(path !== state){
			setState(path);
		}
	}, [path, state])

	if(state === path){
	return (
		<div className={'ad-container-below'} style={{width: '100vw', height: '300px', padding: '25px 0'}}>
			<ins className="adsbygoogle"
				style={{display: "inline-block", width: "300px",height: "250px",position: 'relative', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}
				data-ad-client="ca-pub-1371660541011692"
				data-ad-slot="8545437418" />
		</div>
	);
	}
	else {
		return <div  className={'ad-container-below'} style={{width: '100vw', height: '300px', padding: '25px 0'}}></div>
	}
}



export default { AdSenseDesktopInList, AdSenseMobileAboveTheFold, AdSenseMobileBelowTheFold }





