import React from 'react';
import './food.styles.scss';
import clsx from 'clsx';
import FoodImgRenderer from './FoodImgRenderer';
import { Item, State, ImageRef } from '../../../../models';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export interface FoodProps {
	foods: string[]
	mediaType:string;
	images: {[key:string]: ImageRef}
}

const Food = (props: FoodProps) => {
	const {mediaType, foods, images} = props;
	const history = useHistory();
	const items = useSelector((state:State) => {
		return state.firebase?.items;
	});

	const getItemById = (id:string): Item | null => {
		const item = items?.find((i:Item) => {
			return i.id === id;
		});

		return item ? item : null
	}
	return (
		<div className={clsx('food-container', mediaType)}>
			<div className={'food-title'}>
				food
			</div>
			<div className={'food-body'}>
				<div className={'food-item-container'}>
				{ foods.map((food:string) => {
					return (
						<div key={food} className='food-detail' onClick = {() => { history.push({ pathname: `/items/food/${food}`, state: {return: history.location.pathname}})}}>
							<div className='food-icon'>
								{images ? 
									<FoodImgRenderer imageRef={images['item_'+ food].url} food={food} size={60} />
									: null}
							</div>
							<div className='food-name'>
								{getItemById(food)?.name}
							</div>
						</div>
					)
				})}
				</div>
				
			</div>
			<div className={clsx('food-action-help')}>
				select a food to view more details
			</div>
		</div>
	)
};

export default Food;