import React from 'react';
import createjs from 'createjs-module';
import { withFirebase} from '../../../../api/firebase';

class GenerateBackground extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			imageContainer: new createjs.Container(),
		}	
	}
	componentDidMount(){
		this.props.stage.addChild(this.state.imageContainer);
		
		this.loadImage();
		const imageContainer = this.state.imageContainer;
		imageContainer.x = 0;
		imageContainer.y = 0;
		imageContainer.width = this.props.width;
		imageContainer.height = this.props.height;

		this.setState({imageContainer: imageContainer});
	}

	loadImage = async() => {
		let image = new Image();
		
		image.src = this.props.imageRef;
		
		image.onload = this.updateImage;
	}
	updateImage = (event) => {
		var image = event.target;

		var xratio = this.props.width / image.width,
			yratio = this.props.height / image.height;

    	var scale = Math.min(xratio, yratio);

		var bitmap = new createjs.Bitmap(image);
		
		bitmap.scaleX = bitmap.scaleY = scale;
		bitmap.x = 0;
		if(this.props.size !== 'circular'){
			if(this.props.activeGeneration === 'GEN_ONE'){
				bitmap.y = (this.props.height * (1/8));
			}
			else {
				bitmap.y = this.props.height * (1/8);
			}
		}
		else {
			bitmap.y = 0;
		}

		// if(!this.props.noShadow){
		// 	let g = new createjs.Graphics().beginFill('#000').drawEllipse((this.props.width/2) - 100, (this.props.height -100), 200, 50);
		// 	let shape = new createjs.Shape(g);
		// 	shape.alpha = 0.2;
		// 	this.state.imageContainer.addChild(shape);
		// }
		this.state.imageContainer.addChild(bitmap);
		this.props.stage.update();
	}

	render(){
		return null;
	}
}

export default withFirebase(GenerateBackground);