import Items, {StateProps, DispatchProps, OwnProps, ItemsComponentProps} from './items-component';
import { State, Item } from '../../common/models';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export const mapStateToProps = (state:State):StateProps => {
	return {
		items: state.firebase ? state.firebase.items : null,
		allowedCategories: ['food', 'core', 'restoration', 'shard', 'pet', 'nexotrap', 'special'],
		images: state.firebase?.images ? state.firebase?.images : null
	}
}

export const mapDispatchToProps = (state:State):DispatchProps => {
	return {};
}

export const mergeProps = (stateProps:StateProps, dispatchProps:DispatchProps | null, ownProps:OwnProps):ItemsComponentProps => {
	return {
		...stateProps,
		...dispatchProps,
		...ownProps,
		getAllowed: (category:string, id?:string):boolean | Item | undefined=> {
			if(!stateProps.items) return false;
			if(id){
				if(stateProps.allowedCategories.includes(category)){
					return stateProps.items.find((item:Item) => {
						return item.category === category && item.id === id;
					})
				}
				else {
					return false;
				}
			}
			if(stateProps.allowedCategories.includes(category)){
				return true;
			}
			return false;
		}
	}
}

export default connect<StateProps, DispatchProps, OwnProps, ItemsComponentProps>(mapStateToProps, mapDispatchToProps, mergeProps)(Items);