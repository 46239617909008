import React from 'react';
import {Stats, Nexomon, Evolution} from '../../../../../models';
import hpIcon from '../../../../../../assets/HP.png';
import atkIcon from '../../../../../../assets/ATK.png';
import staIcon from '../../../../../../assets/STAMINA.png';
import defIcon from '../../../../../../assets/DEF.png';
import speedIcon from '../../../../../../assets/SPEED.png';

import './starting-stats.styles.scss';
import { getGen1BaseStats } from '../baseStatsHelpers';
import clsx from 'clsx';
export interface StartingStatsProps {
	nexomon:Nexomon | null;
	activeGeneration: string;
	mediaType:string;
}

const StartingStats = (props:StartingStatsProps) => {
	const {mediaType, nexomon, activeGeneration} = props;
	const stats = nexomon?.startingStats;
	if(activeGeneration === "GEN_ONE" && nexomon){
		const evo = nexomon.evolution?.find((e:Evolution) => { return e.number === nexomon.number});
		if(evo){
			const evoLevel = nexomon.evolution?.indexOf(evo);
			getGen1BaseStats(stats as Stats, evoLevel as number, nexomon.rarity as number);
		}
	}

	if(stats){
	return (
		<div className={clsx('starting-stats-container', mediaType)} >
			<div className='stats-title'>
				starting stats
			</div>
			<div className={'starting-stats'}>
				<div className={'stat-box'}>
					<div className={'stat-title'}>HP</div>
					<div className={clsx('stat-value', {['modified']: activeGeneration==="GEN_ONE"})}>{stats?.hp}</div>
					<div className={'stat-icon'}>
						<img src={hpIcon} alt={'HP'}/>
					</div>
				</div>

				<div className={'stat-box'}>
					<div className={'stat-title'}>ATK</div>
					<div className={clsx('stat-value', {['modified']: activeGeneration==="GEN_ONE"})}>{stats?.attack}</div>
					<div className={'stat-icon'}>
						<img src={atkIcon} alt={'ATK'}/>
					</div>
				</div>

				<div className={'stat-box'}> 
					<div className={'stat-title'}>DEF</div>
					<div className={clsx('stat-value', {['modified']: activeGeneration==="GEN_ONE"})}>{stats?.defense}</div>
					<div className={'stat-icon'}>
						<img src={defIcon} alt={'DEF'}/>
					</div>
				</div>

				<div className={'stat-box'}>
					<div className={'stat-title'}>SPD</div>
					<div className={clsx('stat-value', {['modified']: activeGeneration==="GEN_ONE"})}>{stats?.speed}</div>
					<div className={'stat-icon'}>
						<img src={speedIcon} alt={'SPD'} />
					</div>
				</div>

				<div className={'stat-box'}>
					<div className={'stat-title'}>STA</div>
					<div className={clsx('stat-value', {['modified']: activeGeneration==="GEN_ONE"})}>{stats?.stamina}</div>
					<div className={'stat-icon'}>
						<img src={staIcon} alt={'STA'} />
					</div>
				</div>
			</div>
		</div>
	)
	}
	else {
		return null;
	}
}

export default StartingStats;