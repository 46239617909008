import React from 'react';
import { Skill } from '../../models';
import SkillImgRenderer from './SkillImgRenderer';

export interface SkillIconProps {
	skill:Skill | undefined;
	activeGeneration:string;
	size?:number;
	imageRef:string;
}

const SkillIconComponent = (props:SkillIconProps) => {
	const {imageRef, skill, size, activeGeneration} = props;
	if(skill){
	return (
		<div>
			<SkillImgRenderer number={skill.number} gen={activeGeneration} id={`${activeGeneration.toLowerCase()}_${skill.number}`} size={ size ? size : 100} skill={skill} root={activeGeneration === "GEN_ONE" ? 'gen_1' : 'gen_2'} imageRef={imageRef}/>
		</div>
	)}
	else {
		return null;
	}
}

export default SkillIconComponent;