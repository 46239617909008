import React from 'react';
import clsx from 'clsx';
import ElementDisplayComponent from '../card/element-display/element-display.component';
import strongerThanSrc from '../../../../assets/icons/stronger_than.png';
import weakerThanSrc from '../../../../assets/icons/weaker_than.png';
import { Element } from '../../../models';
import './type-comparison.styles.scss';

export interface TypeComparisonProps {
	elementType: string;
	strongerThan: string[];
	weakerThan: string[];
	mediaType: string;
}

const TypeComparison = (props:TypeComparisonProps) => {
	const { strongerThan, weakerThan, elementType, mediaType} = props;
	return (
		<div className={clsx('type-comparison-container', mediaType)} id={elementType}>
			<div className={'type-comparison'}>
				<div className={clsx('type-comparison-header')}>
					<ElementDisplayComponent showTitle={false} element={elementType as Element} mediaType={mediaType} />
				</div>
				<div className={clsx('comparison-container',  mediaType)}>
					<div className={clsx('comparison-icon', mediaType)}>
						<img src={strongerThanSrc} alt={'stronger than'} />
					</div>
					<div className={'comparison-list'}>
						{strongerThan ? strongerThan.map((str:any) => {
							return <ElementDisplayComponent showTitle={false} element={str as Element} mediaType={mediaType} size={ mediaType === 'mobile' ? 50 : 60} noShadow={true}/>
						}): null}
					</div>
				</div>
				<div className={clsx('comparison-container',  mediaType)}>
					<div className={clsx('comparison-icon', mediaType)}>
						<img src={weakerThanSrc} alt={'weaker than'} />
					</div>
					<div className={'comparison-list'}>
						{weakerThan ? weakerThan.map((str:any) => {
							return <ElementDisplayComponent showTitle={false} element={str as Element} mediaType={mediaType} size={mediaType === 'mobile' ? 50 : 60} noShadow={true}/>
						}): null}
					</div>
				</div>
			</div>
		</div>
	)
}

export default TypeComparison;