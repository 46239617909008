import React, { useEffect } from 'react';
import createjs from 'createjs-module';
import './animated-background.styles.scss';
import GenerateElementBackground from './generate-background/generate-element-background.js';
import clsx from 'clsx';

class ElementBackgroundComponent extends React.Component {
	
	constructor(props){
		super(props);
		this.state = {
			stage: null
		}
	}

	componentDidUpdate(prevProps){
		if(this.state.stage === null){
			const  stage = new createjs.Stage(this.props.id);
			stage.update();
			stage.mouseEnabled = false;
			this.setState({stage: stage})
		}
		if(this.props.name !== prevProps.name){
			if(this.state.stage !== null){
				let stage = this.state.stage;
				stage.enableDOMEvents(false);
				stage.removeAllEventListeners();
				stage.removeAllChildren();
				stage.canvas = null;
				stage = null;
				this.setState({stage: null})
			}
			
		}
	}

	componentDidMount(){
		const  stage = new createjs.Stage(this.props.id);
		stage.update();
		stage.mouseEnabled = false;
		this.setState({stage: stage});
	}

	componentWillUnmount () {
		if(this.state.stage !== null){
		let stage = this.state.stage;
		stage.enableDOMEvents(false);
		stage.removeAllEventListeners();
		stage.removeAllChildren();
		stage.canvas = null;
		stage = null;
		this.setState({stage: null})
		}
	}

	render() {
		return (
			<canvas height={this.props.height} width={this.props.width} id={this.props.id} className={clsx('animated-background', {[`rarity-${this.props.rarity}`]: this.props.rarity})} >
				{this.state.stage ? <GenerateElementBackground height={this.props.height} width={this.props.width} stage={this.state.stage} rarity={this.props.rarity} /> : null}
			</canvas>
		)
	}
	
}

export default ElementBackgroundComponent;