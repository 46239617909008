import firebase from 'firebase';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/storage';

import { userStateChanged } from '../../state/actions/data.actions';

import { Nexomon, Skill, Item, ImageRef } from '../../models';
import { Dispatch } from 'redux';

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const apiBaseUrl = 'https://us-central1-nexopad-2.cloudfunctions.net/api';
export type IFirebase = {firebase: Firebase} | null;

class Firebase {
	auth: firebase.auth.Auth;
	db: firebase.firestore.Firestore;
	analytics: firebase.analytics.Analytics;
	storage: firebase.storage.Storage;

	constructor() {
		app.initializeApp(config);
		this.analytics = app.analytics();
		this.auth = app.auth();
		this.db = app.firestore();
		this.storage = app.storage();
	}

	nexomonQuery = (query:firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>):Nexomon[] => {
		const data:Nexomon[] = [];
		query.forEach((doc:firebase.firestore.DocumentData) => {
			data.push(doc.data() as Nexomon);	
		})
		return data;
	}

	getData = async ():Promise<Nexomon[]> => {
		const data = await fetch(apiBaseUrl + '/data/nexomon/nexomon?key=' + process.env.REACT_APP_APP_VERSION)
		if(data.ok){
			return await data.json();
		}
		else {
			return [];
		}
	}

	getDataGen1 = async ():Promise<Nexomon[]> => {
		const data = await fetch(apiBaseUrl + '/data/nexomon/nexomon_gen1?key=' + process.env.REACT_APP_APP_VERSION)
		if(data.ok){
			return await data.json();
		}
		else {
			return [];
		}
	}

	getAllSkills = async ():Promise<{'GEN_ONE': Skill[], 'GEN_TWO': Skill[]}> => {
		const skills = await fetch(apiBaseUrl + '/data/skills?key=' + process.env.REACT_APP_APP_VERSION);
		if(skills.ok){
			return await skills.json();
		}
		else {
			return {"GEN_ONE": [], "GEN_TWO": []}
		}
	}

	loginAnonymousUser = async (dispatch:Dispatch) => {
		await this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
		const userChanged = (user:firebase.User | null) => {dispatch(userStateChanged(user))};
		this.auth.onAuthStateChanged(userChanged);
		if(this.auth.currentUser !== null){
			dispatch(userStateChanged(this.auth.currentUser));
		}
		else {
			this.auth.signInAnonymously();
		}
	}

	getAllItems = async ():Promise<Item[]> => {
		const data = await fetch(apiBaseUrl + '/data/items?key=' + process.env.REACT_APP_APP_VERSION);
		if(data.ok){
			return await data.json();
		}
		else{
			return [];
		}
	}

	getAllImages = async ():Promise<{[key:string]: ImageRef}> => {
		const data = await fetch(apiBaseUrl + '/data/images?key=' + process.env.REACT_APP_APP_VERSION);
		if(data.ok){
			return await data.json();
		}
		else {
			return {};
		}
	}
};

export default Firebase;