import React from 'react';
import { useRouteMatch, useHistory } from "react-router-dom";
import { Item, ImageRef } from '../../common/models';
import FoodIconComponent from '../../common/components/nexomon/card/food/food-icon-component';
import clsx from 'clsx';
import './item-view.styles.scss';
import { AdSenseDesktopInList, AdSenseMobileBelowTheFold, AdSenseMobileAboveTheFold } from '../../common/components/adsense-components/adsense-components';

export interface ItemViewProps {
	getAllowed: (category:string, id?:string) => boolean | Item | undefined;
	mediaType:string;
	images: {[key:string]: ImageRef} | null;
}

const ItemView = (props:ItemViewProps) => {
	const { getAllowed, images, mediaType } = props;
	const {params} = useRouteMatch<{category:string, id:string}>();

	const history = useHistory();
	const item = getAllowed(params.category, params.id);	
	
	if(!item || !(item as Item) ) {
		history.push('/items');
	}
	
	return (
		<div className={clsx('item-view-container', mediaType)}>
			<div className={clsx('category-header', {['extended']: history.location.state })}>
				{mediaType !== 'mobile' ? 
					<AdSenseDesktopInList  path={history.location.pathname}/>
					: 
					<AdSenseMobileAboveTheFold path={history.location.pathname} />
				}
				<div className={clsx('category-title',{['shorten']: params.category === 'restoration'})}>{params.category}</div>
				<div className={clsx('category-help')} onClick={() => { history.push(`/items/${params.category}`)}}>
					Click here to return to category view.
				</div>
				{ history.location.state ?
					<div className={'category-help'} onClick={() => { history.push((history.location.state as {return:string})['return'])}}>
						Return to {(history.location.state as {return :string})['return'].split('/')[2]} view.
					</div>
					: null
				}
			</div>
			<div className={clsx('item-view', mediaType)}>
				
				<div className={clsx('item-details', mediaType)}>
					<div className={'item-view-name'}>
						{(item as Item).name}
					</div>
					<div className={'item-view-description'}>{(item as Item).description}</div>
				</div>
				<div className={clsx('item-view-icon')}>
					{ images ? <FoodIconComponent food={params.id} size={200} imageRef={images[`item_${params.id}`].url || ''} /> : null}
				</div>
			</div>
			{mediaType !== 'mobile' ? 
				<AdSenseDesktopInList path={history.location.pathname} />
				: 
				<AdSenseMobileBelowTheFold path={history.location.pathname}/>
			}
		  	
		</div>
	)
}

export default ItemView;