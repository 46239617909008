import React, { useEffect } from 'react';
import {Switch, Route, Redirect, useHistory} from 'react-router-dom';
import MediaMapper, {MediaProp} from '../../common/components/media-mapper/media-mapper.comonents';
import NexopediaItem from '../nexopedia/nexopedia-item.component';
import Nexopedia from '../nexopedia/nexopedia';
import LandingPage from '../landing-page/landing-page';
import Firebase, { withFirebase } from '../../common/api/firebase';
import { Nexomon, Skill, Item, ImageRef } from '../../common/models';
import TermsAndConditions from '../terms-and-conditions/terms-and-conditions.component';
import PrivacyPolicy from '../privacy-policy/privacy-policy.component';
import Disclaimer from '../disclaimer/disclaimer.component';
import CookieAcceptance from '../../common/components/cookie-acceptance/cookie-acceptance.component';
import Items from '../items/items';
import Guide from '../guide/guide';

import { useDispatch } from 'react-redux';
//import Login from '../../common/components/login/login';
//import Register from '../../common/components/register/register';
import './route-container.styles.scss';
import TestPath from '../testPath.component';
import SocialPage from '../social-page/social-page.component';
export interface StateProps {
	nexomon:Nexomon[],
	nexomonGen1: Nexomon[],
	skills: { GEN_ONE: Skill[], GEN_TWO: Skill[]} | null;
	items: Item[] | null;
	user: firebase.User | null;
	images: {[key:string]: ImageRef}
}

export interface DispatchProps {
	getNexomon: (firebase:Firebase) => void;
	getNexomonGen1: (firebase:Firebase) => void;
	getSkills: (firebase:Firebase) => void;
	getItems: (firebase:Firebase) => void;
	getImages: (firebase:Firebase) => void;
}

export interface OwnProps {
	mediaType: string;
}

export type RouteContainerProps = StateProps & DispatchProps;

export const RouteContainerComponent = (props:RouteContainerProps & {mediaType: MediaProp} & {firebase:Firebase}) => {
	const {user, images, getImages, getSkills, getNexomon, getNexomonGen1, items, getItems, nexomon, nexomonGen1, skills, mediaType, firebase } = props;
	const dispatch = useDispatch();

	const history = useHistory();
		useEffect(() => {
			document.title = 'Nexopad ' + history.location.pathname.split('/').map((s:string) => {
				if(s){
					return s[0].toUpperCase() + s.slice(1);
				}
				else { 
					return '';
				}
			}).join(' ');
	}, [history])
	history.listen((location:{pathname: string}) => {
		document.title = 'Nexopad ' + location.pathname.split('/').map((s:string) => {
			if(s){
				return s[0].toUpperCase() + s.slice(1);
			}
			else { 
				return '';
			}
		}).join(' ');
	})

	useEffect(() => {
		if(nexomon.length === 0 && firebase) {
			getNexomon(firebase);
		}
		if(nexomonGen1.length === 0 && firebase) {
			getNexomonGen1(firebase);
		}
		if(!skills && firebase) {
			getSkills(firebase);
		}
		if(!items && firebase) {
			getItems(firebase);
		}

		if(!user && firebase){
			firebase.loginAnonymousUser(dispatch);
		}

		if(!images && firebase) {
			getImages(firebase);
		}
	
	}, []);
	return (
		<div className={'router-container'}>
			{/* <Login mediaType={mediaType} /> */}
			<CookieAcceptance mediaType={mediaType} />
			<Switch>
				{/* <Route exact path='/test'>
					<TestPath />
				</Route> */}
				<Route exact path='/home'>
					<LandingPage mediaType={mediaType} />
				</Route>
				<Route path='/nexopedia/:id'>
					<NexopediaItem mediaType={mediaType} />
				</Route>
				<Route path='/nexopedia'>
					<Nexopedia mediaType={mediaType} />
				</Route>
				
				<Route path='/terms-and-conditions'>
					<TermsAndConditions mediaType={mediaType}/>
				</Route>
				<Route path='/privacy-policy'>
					<PrivacyPolicy mediaType={mediaType}/>
				</Route>
				<Route path='/disclaimer'>
					<Disclaimer mediaType={mediaType}/>
				</Route>
				<Route path='/items'>
					<Items mediaType={mediaType} />
				</Route>
				<Route path='/guide'>
					<Guide mediaType={mediaType} />
				</Route>
				<Route path='/social'>
					<SocialPage mediaType={mediaType} />
				</Route>
				<Route path="*">
					<Redirect to={'/home'} />
				</Route>
			</Switch>
		</div>
	)
}

const RouteMediaMap = (props:RouteContainerProps & {firebase:Firebase}) => {
	return (
		<MediaMapper>
			<RouteContainerComponent {...props} mediaType={'mobile'} />
			<RouteContainerComponent {...props} mediaType={'tablet'} />
			<RouteContainerComponent {...props} mediaType={'desktop'} />
		</MediaMapper>
	)
}

export default withFirebase(RouteMediaMap);