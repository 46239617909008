import clsx from 'clsx';
import React, { useEffect } from 'react';
import './social-page.styles.scss';

export interface SocialPageProps {
	mediaType:string;
}
const SocialPage = (props: SocialPageProps) => {
	const { mediaType } = props;

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://platform.twitter.com/widgets.js";
		script.async = true;
	
		document.body.appendChild(script);
	}, [])

	return (
		<div className={clsx('social-page-container', mediaType)}>
			<div className={clsx('social-page', mediaType)}>
				<a className="twitter-timeline" href="https://twitter.com/NexomonGame?ref_src=twsrc%5Etfw">Tweets by NexomonGame</a>
			</div>
        </div>
	)
}

export default SocialPage;