import React, { useState, useEffect } from 'react';
import { Nexomon, Evolution, Stats } from '../../../../../models';
import hpIcon from '../../../../../../assets/HP.png';
import atkIcon from '../../../../../../assets/ATK.png';
import staIcon from '../../../../../../assets/STAMINA.png';
import defIcon from '../../../../../../assets/DEF.png';
import speedIcon from '../../../../../../assets/SPEED.png';

import './gen-one-stat-calculator.styles.scss';
import clsx from 'clsx';
import { update } from '../baseStatsHelpers';

export interface GenOneStatCalculatorProps {
	nexomon:Nexomon;
	mediaType:string;
}

const GenOneStatCalculatorComponent = (props:GenOneStatCalculatorProps) => {
	const { nexomon, mediaType } = props;
	const [selection, setSelection] = useState('regular');
	const [level, setLevel] = useState(0);
	const [stats, setStats] = useState<Stats>(nexomon.startingStats as Stats);

	const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => { 
		if(isNaN(parseInt(event.target.value)) || parseInt(event.target.value) < 0){
			setLevel(0);
		}
		else {
			setLevel(parseInt(event.target.value));
		}
	};
	useEffect(() => {
		if(nexomon && selection){
			const evo = nexomon.evolution?.find((e:Evolution) => { return e.number === nexomon.number});
			if(evo){
				const evoLevel = nexomon.evolution?.indexOf(evo);
				const s:Stats = Object.assign({}, {...nexomon.startingStats}) as Stats;
				const stat = update(Object.assign({}, s), evoLevel as number, nexomon.rarity as number, level ? level : 0, selection === 'reborn');
				setStats({...stat});
			}
		}
	}, [nexomon, selection, level]);
	const changeSelection = () => {
		setSelection(selection === 'regular' ? 'reborn' : 'regular');
	}
	
	return (
		<div className={clsx('stat-calculator-container', mediaType)}>
			<div className={clsx('stat-calculator-title', mediaType)}>stats calculator</div>
			<div className={clsx('stat-calculator-body', mediaType)}>
				<div className={clsx('stats-row', mediaType)}>
					<div className={clsx('level-type-selector', mediaType)}>
						<div className={clsx('title', mediaType)}>level type</div>
						<div className={clsx('switch', mediaType)}>
							<div className={clsx('regular', mediaType,{['shift']: selection === 'regular'})} onClick={() => {changeSelection()}}>
								regular
							</div>
							<div className={clsx('reborn', mediaType,{['shift']: selection === 'reborn'})} onClick={() => {changeSelection()}}>
								reborn
							</div>
							<div className={clsx('slider', mediaType, {['shift']: selection === 'reborn'})}></div>
							<div className={clsx('channel', mediaType)} />
						</div>
					</div>

					<div className={clsx('level-selector', mediaType)}>
						<div className={clsx('title', mediaType)}>level</div>
						<div className={clsx('container', mediaType)}>
							<input maxLength={8} type='text' className={clsx('number-input', mediaType)} pattern={'[0-9]+'} onChange={handleChange}/>
							<div className={clsx('channel', mediaType)} />
						</div>
					</div>
				</div>
				<div className={clsx('stats-display-row', mediaType)}>
					<div className={clsx('stat-box', mediaType)}>
						<div className={clsx('stat-title', mediaType)}>HP</div>
						<div className={clsx('stat-value', mediaType, {['modified']: (stats?.hp.toString().length ? stats?.hp.toString().length : 0) >= 3})}>
							<span>
								{stats?.hp}
							</span>
						</div>
						<div className={clsx('stat-icon', mediaType)}>
							<img src={hpIcon} alt={'HP'}/>
						</div>
					</div>

					<div className={clsx('stat-box', mediaType)}>
						<div className={clsx('stat-title', mediaType)}>ATK</div>
						<div className={clsx('stat-value', mediaType,{['modified']: (stats?.attack.toString().length ? stats?.attack.toString().length : 0) >= 3})}>
							<span>
								{stats?.attack}
							</span>
						</div>
						<div className={clsx('stat-icon', mediaType)}>
							<img src={atkIcon} alt={'ATK'}/>
						</div>
					</div>

					<div className={clsx('stat-box', mediaType)}> 
						<div className={clsx('stat-title', mediaType)}>DEF</div>
						<div className={clsx('stat-value', mediaType, {['modified']:(stats?.defense.toString().length ? stats?.defense.toString().length : 0) >= 3})}>
							<span>
								{stats?.defense}
							</span>
						</div>
						<div className={clsx('stat-icon', mediaType)}>
							<img src={defIcon} alt={'DEF'} />
						</div>
					</div>

					<div className={clsx('stat-box', mediaType)}>
						<div className={clsx('stat-title', mediaType)}>SPD</div>
						<div className={clsx('stat-value', mediaType,{['modified']: (stats?.speed.toString().length ? stats?.speed.toString().length : 0) >= 3})}>
							<span>
								{stats?.speed}
							</span>
						</div>
						<div className={clsx('stat-icon', mediaType)}>
							<img src={speedIcon} alt={'SPD'}/>
						</div>
					</div>

					<div className={clsx('stat-box', mediaType)}>
						<div className={clsx('stat-title', mediaType)}>STA</div>
						<div className={clsx('stat-value', mediaType, {['modified']: (stats?.stamina.toString().length ? stats?.stamina.toString().length : 0) >= 3})}>
							<span>
								{stats?.stamina}
							</span>
						</div>
						<div className={clsx('stat-icon', mediaType)}>
							<img src={staIcon} alt={'STA'}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default GenOneStatCalculatorComponent;