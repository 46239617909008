import React from 'react';
import { Route, Switch, Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import './guide.styles.scss';
import clsx from 'clsx';
import ElementChart from '../../common/components/nexomon/type-chart/type-chart.component';
import StatusEffect from './status-effect/status-effect.component';
import { State } from '../../common/models';
import { useSelector } from 'react-redux';
import Walkthrough from './walkthrough/walkthrough.component';

export interface StateProps {
}

export interface DispatchProps {

}

export interface OwnProps {
	mediaType:string;
}

export interface GuideComponentProps extends StateProps, DispatchProps, OwnProps {
	
};

const GuideComponent = (props:GuideComponentProps) => {
	const { mediaType } = props;
	const history = useHistory();
	const match = useRouteMatch();
	const skills = useSelector((state:State) => {
		return state.firebase?.skills ? state.firebase?.skills['GEN_TWO'] : [];
	});

	return (
		<div className={clsx('guide-page-container', mediaType)}>
			<div className={clsx('guide', mediaType)}>
				<div className={clsx('guide-menu', mediaType)}>
					<div className={clsx('button-group')}>
						<button onClick={() => { window.location.assign(`${window.location.protocol}//${window.location.host}/guide/elements`)}}>elements</button>
						<button onClick={() => { window.location.assign(`${window.location.protocol}//${window.location.host}/guide/status_effect`)}}>status effect</button>
						<button onClick={() => { window.location.assign(`${window.location.protocol}//${window.location.host}/guide/walkthrough`)}}>walkthrough</button>
					</div>
				</div>
				<div className={clsx('guide-body', mediaType)}>
					<Switch>
						<Route path={match.path + '/elements'}>
							<ElementChart mediaType={mediaType} />
						</Route>
						<Route path={match.path + '/status_effect'}>
							<StatusEffect mediaType={mediaType} gen2SkillList={skills}/>
						</Route>
						<Route path={match.path + '/walkthrough'}>
							<Walkthrough mediaType={mediaType}/>
						</Route>
						<Route exact path={match.path }>
							<Redirect to={'/guide/elements'} />
						</Route>
					</Switch>
				</div>
			</div>
		</div>
	)
}

export default GuideComponent;