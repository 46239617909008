import Guide, {StateProps, DispatchProps, OwnProps, GuideComponentProps} from './guide-component';
import { State, Item } from '../../common/models';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export const mapStateToProps = (state:State):StateProps => {
	return {};
}

export const mapDispatchToProps = (state:State):DispatchProps => {
	return {};
}

export const mergeProps = (stateProps:StateProps, dispatchProps:DispatchProps | null, ownProps:OwnProps):GuideComponentProps => {
	return {
		...stateProps,
		...dispatchProps,
		...ownProps
	}
}

export default connect<StateProps, DispatchProps, OwnProps, GuideComponentProps>(mapStateToProps, mapDispatchToProps, mergeProps)(Guide);