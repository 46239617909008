import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import LandingPageContainer, { StateProps, DispatchProps, OwnProps, LandingPageProps} from './landing-page.component';
import { State, Nexomon } from '../../common/models';
import Firebase, { withFirebase } from '../../common/api/firebase';
import { evolutionUpdate } from '../../common/api/helpers/evolutionUpdateMethod';
import { MediaProp } from '../../common/components/media-mapper/media-mapper.comonents';

export const mapStateToProps = (state:State):StateProps => {
	return {
		nexomon: state.firebase?.nexomon ? state.firebase.nexomon : [],
		evolutionsUpdate: evolutionUpdate,
	}
} 

export const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => {
	return {
		
	}
}



export const mergeProps = (stateProps:StateProps, dispatchProps:DispatchProps, ownProps:OwnProps):LandingPageProps => {

	return {
		...stateProps,
		...dispatchProps,
		
		mediaType: ownProps.mediaType
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withFirebase(LandingPageContainer));